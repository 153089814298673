import React, {createContext, useState, useContext, useEffect} from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import AuthService from '../../services/AuthService'

const loadThemeFromStorage = () => {
    let themeOption = localStorage.getItem("_themeOptions")
    if (themeOption){
        return JSON.parse(themeOption)
    }
    //thema default
    return {
        primary: '#253257',
        secondary: '#F2C26A',
        background: '#ffffff', 
    }
 };

 export const saveThemeOptions = (options) =>{
    localStorage.setItem("_themeOptions", JSON.stringify( options ))
 }

 export const createTheme = (options) => createMuiTheme({
    palette: {
      type: 'light',
      primary: { main: options.primary },
      secondary: { main: options.secondary },
      background: {
        //default: options.primary,
        main: '#ffffff' //options.background
      },
      personal: {
        paperBackground: '#ffffff',
      }
    },
    overrides: {
      MuiButton: {
        contained: {
          boxShadow: 'none',
        }
      },
      MuiFab: {
        extended: {
          boxShadow: 'none',
        }
      }
    },
  });

export const ThemeContext = createContext();

export default function MyThemeProvider({children}){

    const defaultTheme = createTheme(loadThemeFromStorage());
    const [myTheme, setMyTheme] = useState(defaultTheme)

    useEffect(() => { 

        let service = new AuthService()
        let hash = service.getToken()
        if (hash){
            //se tiver algum hash, atualiza o thema
            service
            .get(`/identificador/load/${hash}`)
                .then(rest => {
                    let temaStr = rest.empresa.tema
                    service.setProfile(rest)
                    if (temaStr){
                        let themeOptions = JSON.parse(temaStr)
                        let theme = createTheme(themeOptions)
                        setMyTheme(theme)
                    }
                    
                }).catch(err=>{
                    console.error(err)
                })
        } else {
          console.log("Sem hash ainda (tema não carregado)")     
        }
        
    }, []);
    
    return (
        <ThemeContext.Provider value={{ myTheme, setMyTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export function useThemeContext(){
    const context = useContext(ThemeContext)
    const {myTheme, setMyTheme} = context;
    return {myTheme, setMyTheme}
}