import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import Errors from "../../imagens/Error.svg";
import Button from '@material-ui/core/Button';

import AvisoGenerico from "../generic/AvisoGenerico"

class ErrorLojaNaoEncontrada extends Component {
    render() {
        return (
            <AvisoGenerico>
                 <Box width="1" height="100%" textAlign="center" >
                    <Box style={{ paddingTop: '40%' }} textAlign="center">
                        <img src={Errors} alt="Não encontramos a loja com o endereço informado"/>
                    </Box>
                    <Typography variant="h5" color="primary"><strong>Não encontramos a loja no o endereço informado</strong></Typography>
                    <Typography variant="subtitle1" color="primary">Tente conferir o endereço digitado ou faça a leitura do qrcode novamente</Typography>
                    <Box width={1} pt={2}>
                        <Button variant="contained" color="primary" onClick={() => { this.props.history.replace('/readQRcode') }}>
                            Vou tentar ler o qrcode
                     </Button>
                    </Box>
                </Box>
            </AvisoGenerico>
        );
    }
}
export default ErrorLojaNaoEncontrada;



