import React, { Component } from 'react'
import { CssBaseline, Box, Card, CardContent, Grid, Typography, CardActions, Button, Paper } from '@material-ui/core'
import CustomButton from '../../generic/CustomButton'
import CardGenerico from '../../generic/CardGenerico'

import {
    deleteSequencial
} from '../../../utils/PedidoUtil';

const styles = {
    paperContainer: {
        backgroundRepeat: "repeat-xy",
        backgroundColor: "transparent",
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        border: ""
    }
};

export class MostraSequencial extends Component {
    constructor(props){
        super(props)
    }

    componentWillUnmount(){
        deleteSequencial();
    }

    handleClosePage() {
        this.props.history.replace("/");
    }
    
    render() {
        let sequencial = localStorage.getItem("_sequencial");
        

        return (
            <CardGenerico
            cardContent={
                <Box pt={2}>
                <div style={styles.paperContainer} >

                    <Grid container>
                        <Grid item style={{ textAlign: 'center', marginTop: "50%" }} xs={12}>
                            <Grid width="100%" item sm={12}>

                                <Typography style={{ fontFamily: "Bebas Neue", fontWeight: "400", fontSize: "200%" }} color={"primary"}>
                                    SUA SENHA É
                                </Typography>

                            </Grid>
                            <Grid item sm={12}>
                                <Typography style={{ fontFamily: "Bebas Neue", fontWeight: "400", fontSize: "400%" }} color={"primary"}>
                                   {sequencial}
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Box mt={6} px={3}>
                                <Typography color="primary" variant="subtitle1">
                                    Caso você esqueça, poderá encontrá-la nos pedidos realizados
                                </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                    </Grid>
            </div>
            </Box>
            }
            cardActions={
                <CustomButton variant="contained" color="secondary"
                    fullWidth onClick={() => this.handleClosePage()}>
                  Voltar ao cardápio
                </CustomButton>
            }>
            
            </CardGenerico>
        )
    }
}

export default MostraSequencial
