import React from "react";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
//import FundoQrpedir from "../../imagens/fundo-qrpedir-vetorial.svg";
import { useTheme } from '@material-ui/core/styles';
import FundoQrpedir from "../../imagens/fundo-qrpedir-min.png";
 

function AvisoGenerico(props) {
    
    const theme = useTheme();

    const styles ={
        containerAvisos: {
            background: `${ theme.palette.secondary.main } url(${FundoQrpedir}) repeat`,
            height: '100vh',
        },
        background: {
            backgroundColor: theme.palette.primary.main
        }
    } 

    return (
        <div style={ styles.background }>
            <Container maxWidth="sm" style={ styles.containerAvisos }>
            <CssBaseline />
            {props.children}    
            </Container>
        </div>
        
    );
}
export default AvisoGenerico;


