import ReactPixel from 'react-facebook-pixel';
import { getPerfile } from './PedidoUtil';

const getConfig = () => {
    let profile = getPerfile();

    let hasFacebookID = profile && profile.empresa && profile.empresa.pixelFacebookId
    if (!hasFacebookID) {
        return null
    }

    let ID = profile.empresa.pixelFacebookId;
    let OPTIONS = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
    };

    return {
        id: ID,
        options: OPTIONS
    }
}


export const init = () => {
    if (isPixelOn()) {
        let config = getConfig();
        console.log("PIXEL FACEBOOK INICIADO", config.id);
        ReactPixel.init(config.id, config.options);
    } else {
        console.log("PIXEL FACEBOOK NAO CONFIGURADO");
    }
}

export const trackPageView = () => {
    console.log("TRACKING PAGE VIEW");
    ReactPixel.pageView(); // For tracking page view
}

export const trackEvent = (event, data) => {
    ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
}

export const trackSingle = (event, data) => {
    let config = getConfig();
    ReactPixel.trackSingle(config.id, event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
}

export const trackCustom = (event, data) => {
    ReactPixel.trackCustom(event, data); // For tracking custom events
}

export const trackSingleCustom = (event, data) => {
    let config = getConfig();
    ReactPixel.trackSingleCustom(config.id, event, data); // For tracking custom events
}

export const isPixelOn = () => {
    let profile = getPerfile();
    let hasFacebookID = profile && profile.empresa && profile.empresa.pixelFacebookId
    return hasFacebookID
}