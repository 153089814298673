import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    IconButton
} from '@material-ui/core';
import AuthService from '../../../services/AuthService';
import Utils, { trunkStr, converterURLImagemProduto } from '../../../utils/Utils'
import { useHistory } from 'react-router-dom';
import PromoChip from '../../generic/PromoChip';
import ItemsCarousel from 'react-items-carousel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useDispatch } from 'react-redux'

function PainelPromocao() {

    const useStyles = makeStyles((theme) => ({
        promoTitle: {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontFamily: "Bebas Neue",
            fontSize: '1.4em'
        },
        chevronBtn: {
            //backgroundColor: "rgba(255, 255, 255)"
            backgroundColor: theme.palette.personal.paperBackground,
        }
    }));

    const dispatch = useDispatch()
    const chevronWidth = 16;
    const [produtos, setProdutos] = useState([]);
    const classes = useStyles();
    const history = useHistory();
    const [activeItemIndex, setActiveItemIndex] = useState(0);


    useEffect(() => {
        let service = new AuthService();

        /** SO CARREGA OS PRODUTOS SE ESTIVER COM TOKEN */
        if (!service.loggedIn()) {
            return;
        }

        service
            .get("/produto/listPromocao")
            .then(retorno => {
                setProdutos(retorno)
            })
            .catch(err => {
                history.replace("/errors");
            });
    }, []);


    const onClickItemPromocao = (produto) => {
        dispatch({ type: 'OPEN_PRODUCT', product: produto })
    }


    return (
        produtos && produtos.length > 0 ? (
            <Box>
                <Box textAlign="center" pt={2}>
                    <Typography
                        display="block" variant="h6"
                        component="h2" className={classes.promoTitle} >
                        Em promoção
                    </Typography>
                </Box>

                <Box pt={1} pb={1}>

                    <div style={{ padding: `0 ${chevronWidth}px` }}>
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={2.5}
                            gutter={10}
                            leftChevron={
                                <IconButton size="small" className={classes.chevronBtn}><ChevronLeftIcon /></IconButton>
                            }
                            rightChevron={
                                <IconButton size="small" className={classes.chevronBtn}><ChevronRightIcon /></IconButton>
                            }
                            outsideChevron={true}
                            chevronWidth={chevronWidth}
                            numberOfPlaceholderItems={3}
                            slidesToScroll={2}
                        >

                            {produtos.map(produto => (
                                <CardPromocao produto={produto} key={produto.id} onClick={() => onClickItemPromocao(produto)} />
                            ))}

                        </ItemsCarousel>
                    </div>
                </Box>


            </Box>
        ) : (
            null
        )
    )
}

function CardPromocao({ produto, onClick }) {
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 220,
            minHeight: 150,
            maxHeight: 150,
            //backgroundColor: "rgba(255, 255, 255)", // theme.palette.secondary.light,
            backgroundColor: theme.palette.personal.paperBackground,
        },
        content: {

        },
        media: {
            height: 70,
            objectFit: 'contain'
        },

        mediaDefault: {
            height: 60
        },
    }));

    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={0}>
            <CardActionArea onClick={onClick} className={classes.content}>

                {produto.imagens && produto.imagens.length > 0 ? (
                    <CardMedia
                        className={classes.media}
                        image={converterURLImagemProduto(produto.imagens[0])}
                        alt={produto.nome}
                        title={produto.nome}
                    />
                ) : (
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                        <img
                            className={classes.mediaDefault}
                            alt={produto.nome}
                            src={`/assets/images/iconsCircles/${produto.grupoProduto.tipoGrupoProduto.imagem}`}
                        />
                    </div>
                )}

                <CardContent style={{ padding: "6px", textAlign: "center" }}>

                    <Box style={{ height: "40px" }}>
                        <Typography gutterBottom variant="overline" display="block" style={{ lineHeight: "normal" }} >
                            {trunkStr(produto.nome, 30)}
                        </Typography>
                    </Box>
                    <PromoChip label={Utils.formatNumber(produto.preco)} />

                </CardContent>


            </CardActionArea>
        </Card>
    )
}


export default PainelPromocao;