import { createStore } from 'redux';

const INITIAL_STATE = {
    cart: {
         open: true
    },
    productState: { product: null, open: false },
    searchProduct: {search: '', open: false}
}

function reciveAction(state = INITIAL_STATE, action) {
    switch(action.type){
        case 'VISIBILITY_CART':
            return { 
                ...state, cart: { open:  action.open } 
            };

        case 'OPEN_PRODUCT':
            return { 
                ...state, productState: { product: action.product, open: true }
            };

        case 'CLOSE_PRODUCT':
            return { 
                ...state, productState: { product: null, open: false }
        };

        case 'SEARCH_PRODUCT':
            return {
                ...state, searchProduct: {search: action.searchProduct.search, open: action.searchProduct.open}
        };
        
        default:
            return state;
    }
}

const store = createStore(reciveAction);

export default store;