import React, { Component } from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import { getUltimoPedido, getPerfile } from '../../../utils/PedidoUtil';
import Bandeja from "../../../imagens/BandejaGarcon.svg";
import AuthService from "../../../services/AuthService";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CustomButton from '../../generic/CustomButton'
import CardGenerico from '../../generic/CardGenerico'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const TOTAL_TENTATIVAS = 60
const INTERVALOR_ENTRE_TENTATIVAS = 5000 //em milis

const FRASES_ESPERA = [
    "Confirmando em 3, 2, 1...",
    "Mais um pouquinho. Estamos quase lá",
    "Sei que está com pressa... Estamos quase",
    "Aguenta aí",
    "Knock knock Penny!",
    "Mais um minutinho... tá qse",
    "Tá saindo da JAULA o pedido. BIIIIRL!!!",
    "Aguarde...",
    "Destinatário ausente. Brincadeirinha 😬",
    "Why so serious? 🃏",
    "Houston, cadê meu pedido?",
    "My precious... onde está?",
    "Vou ali e já volto...",
    "ET ... telefone ... casa ... meu pedido?",
    "Meu nome é Maximus Decimus Meridius, e estou com fome!",
    "Winter’s coming” e o seu pedido também.",
    "Já sinto cheiro de sucesso!",
    "Calma, calma! Não criemos pânico.",
    "E essa cara brava? É fome? Relaxa a vida é bela.",
    "Estou vendo aqui 🔮, seu role favorito é comer!",
    "Você esperando 😤 Quando sua comida chega 😇.",
    "Comer muito e ser feliz! Nosso propósito de vida.",
    "“Que a força esteja com você”  e que seu pedido seja confirmado logo.🙏",
    "Para, para, para, para. O que você disse? 😲",
    "Ninguém se mexe”, meu pedido!",
    "Espera só mais um tiquinho assim! 🤏",
    "Vamos lá respira comigo! Inspira... Expira...",
    "Você já praticou a gratitude hoje? Vamos lá!",
]

const FRASES_PEDIDO_CONFIRMADO = [
    "Tudo ok, pedido confirmado!",
    "Pode comemorar, tá confirmado!😎",
    "Prontinho. Pedido confirmado!",
    "Aguenta aí que daqui a pouco o seu pedido chega. Confirmado!",
    "Glória! o pedido tá confirmado!👐",
    "Aguarda napos, estamos produzindo!",
    "Porque choras? 😥 Seu pedido tá confirmado.",
    "Que os jogos comecem. Pedido confirmado",
    "Tudo pronto! “Não contavam com a minha astúcia”, não é mesmo?",
    "Ooooh e a confirmação chegoooou!",
    "“Você não soube? Não te disseram? Não te contaram?” Seu pedido foi confirmado!",
    "Contagem regressiva para felicidade 3... 2... 1... Confirmado!",
    "“Que é que há, velhinho?” Pedido confirmado!",
    "Confirmadíssimo irmãos, é pra glorificar de pé!",
    "Agora sim, hein? Confirmado!",
    "Calma, tenho certeza que está tudo bem! Olha aí, confirmado. Te falei.😎",
    "Segura o coração! Pronto, confirmadérrimo!",
    "Olha essa ansiedade faz mal para sua pele! Pedido confirmado!",
    "Calma, deixa eu conferir aqui! Ah sim, tá confirmado.😂",
    "É teeeetra!É teeeetra!"
]



class Pedido extends Component {

    interval = null

    constructor(props) {
        super(props);
        this.state = {
            tentativas: TOTAL_TENTATIVAS,
            percentual: 0.0,
            fraseEspera: "",
            situacao: "PENDENTE"
        }

        this.finalizarInterval = this.finalizarInterval.bind(this)
        this.verificaMesa = this.verificaMesa.bind(this)
        this.handleClosePage = this.handleClosePage.bind(this)
        this.getFraseAleatoria = this.getFraseAleatoria.bind(this)
        this.abrirTelaFinalizacaoDelivery = this.abrirTelaFinalizacaoDelivery.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(this.consultaSituacaoUltimoPedido, INTERVALOR_ENTRE_TENTATIVAS);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    finalizarInterval() {
        let { situacao } = this.state
        clearInterval(this.interval)
        this.setState({ fraseEspera: this.getFraseAleatoria(situacao) })

        let ultimosPedidos = JSON.parse(localStorage.getItem("_ultimosPedidos"));
        let pedido = ultimosPedidos[ultimosPedidos.length - 1];

        if (pedido.tipoPedido === "DELIVERY"
            && situacao === "CONFIRMADO") {
            this.abrirTelaFinalizacaoDelivery(pedido);
        }

        console.log("limpando interval")
    }

    abrirTelaFinalizacaoDelivery(pedido) {
        setTimeout(() => { 
            this.props.history.replace(`/infoPedido/${pedido.uuid}`)
        }, 2500)
    }

    getFraseAleatoria = (situacao) => {
        if (situacao !== "CONFIRMADO")
            return FRASES_ESPERA[parseInt(Math.random() * FRASES_ESPERA.length)]

        if (situacao === "CONFIRMADO")
            return FRASES_PEDIDO_CONFIRMADO[parseInt(Math.random() * FRASES_PEDIDO_CONFIRMADO.length)]
    }

    consultaSituacaoUltimoPedido = () => {

        let { tentativas, percentual, situacao } = this.state

        this.setState({ fraseEspera: this.getFraseAleatoria(situacao) })


        if (tentativas <= 0) {
            //tentativas esgotadas, sem confirmação
            this.setState({ situacao: 'NAO_CONFIRMADO' })
            this.finalizarInterval()
            return;
        }
        percentual = percentual + (100.0 / TOTAL_TENTATIVAS)
        tentativas -= 1;
        this.setState({ tentativas, percentual })

        console.log("tentativas", tentativas)
        console.log("percentual", percentual)

        let ultimoPedido = getUltimoPedido()
        let service = new AuthService();
        service
            .get(`/pedido/uuid/${ultimoPedido.uuid}`)
            .then(pedido => {

                /* se já foi confirmado (mesmo que o status atual seja outro, marca como 'CONFIRMADO') */
                if (pedido.dataConfirmado){
                    let situacao = "CONFIRMADO";
                    this.setState({ situacao })
                    this.finalizarInterval()
                }

                if(pedido.dataCancelado){
                    this.finalizarInterval()
                    this.abrirTelaFinalizacaoDelivery(pedido)
                }

                let today = new Date().getTime()
                let datePendente = new Date(pedido.dataPedido)

                if (pedido.tipoPedido === 'DELIVERY') {
                    var diffMs = (today - datePendente);
                    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

                    if (diffMins >= 1) {
                        this.finalizarInterval()
                        this.abrirTelaFinalizacaoDelivery(pedido) 
                    }
                }

                
            }).catch(err => {
                console.error(err)
            })
    }

    handleClosePage() {
        this.finalizarInterval()
        this.props.history.replace("/");
    }

    verificaMesa() {
        if (getPerfile().empresa.tipoIdentificadorPrincipal === "MESA") {
            return getPerfile().numero;
        } else if (getPerfile().empresa.tipoIdentificadorSecundario === "MESA") {
            return localStorage.getItem("_numeroIdentificador");
        }
        return null;
    }



    render() {
        const { fraseEspera, situacao } = this.state
        let identificador = this.verificaMesa();
        return (
            <React.Fragment>
                <CardGenerico
                    cardActions={
                        <React.Fragment>
                            {getPerfile().tipo !== "DELIVERY" ? (
                                <CustomButton
                                    variant="contained"
                                    size="large" color="secondary"
                                    fullWidth
                                    onClick={() => this.handleClosePage()}
                                    disabled={situacao === 'PENDENTE'}>
                                    Voltar ao cardápio
                                </CustomButton>
                            ) : null}
                        </React.Fragment>
                    } >


                    <Grid container>
                        <Grid item style={{ textAlign: 'center', marginTop: "20%" }} xs={12}>
                            <Grid width="100%">
                                <Box style={{ margin: "0 auto" }}>
                                    <img alt='bandeja' src={Bandeja} style={{ maxHeight: "25vh" }} />
                                </Box>

                            </Grid>
                        </Grid>
                        <Grid item style={{ textAlign: 'center', marginTop: "10%" }} xs={12}>
                            <Typography color="primary">
                                {getPerfile().tipo === "DELIVERY" || getPerfile().tipo === "SEQUENCIAL" ? (
                                    <strong>Quase lá...</strong>
                                ) : (
                                        identificador ? (
                                            <strong>Seu pedido vai ser <br /> entregue na mesa {identificador}.</strong>
                                        ) : (
                                            <strong>Seu pedido foi realizado</strong>
                                        )
                                       
                                    )}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <AguardandoConfirmacao situacao={situacao} fraseEspera={fraseEspera} />
                        </Grid>
                        {situacao === 'CONFIRMADO' && getPerfile().tipo === "SEQUENCIAL" ? this.props.history.replace("/Senha") : null}

                    </Grid>

                </CardGenerico>

            </React.Fragment>
        );
    }
}



function BotaoWhatsApp(props) {
    let foneWhatsapp = AuthService.getProfile().empresa.foneWhatsapp || ""
    foneWhatsapp = "55" + foneWhatsapp.replace(/\D/g, "")

    const mensagem = props.mensagem;
    const visibility = props.visibility === null ? true : props.visibility;
    return visibility && foneWhatsapp.length > 10 ? (
        <Box display="flex" justifyContent="center" >
            <Box p={1} >
                <Button
                    target="_blank"
                    href={`https://wa.me/${foneWhatsapp}?text=${mensagem}`}
                    size="small"
                    startIcon={<WhatsAppIcon />} >
                    Conversar pelo WhatsApp
            </Button>
            </Box>
        </Box>
    ) : null
}

function AguardandoConfirmacao(props) {
    const ultimoPedido = getUltimoPedido()

    return (
        <React.Fragment>

            <List style={{ width: '100%' }} >
                <ListItem>
                    <ListItemText
                        primary="Pedido recebido"
                    />
                    <ListItemSecondaryAction>
                        <CheckIcon color="primary" />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary="Pedido em produção"
                        secondary={props.fraseEspera}
                    />
                    <ListItemSecondaryAction>

                        {props.situacao === 'PENDENTE' ? (<CircularProgress size={30} color="primary" />) : null}
                        {props.situacao === 'CONFIRMADO' ? (<CheckIcon color="primary"/>) : null}

                    </ListItemSecondaryAction>
                </ListItem>

            </List>

            {props.situacao === 'CONFIRMADO' ? (
                <Box textAlign="center" display="block" pt={2} >
                    <Typography color="primary" > Só relaxar e aguardar! </Typography>
                </Box>
            ) : null}

            {props.situacao === 'NAO_CONFIRMADO' ? (
                <Box style={{ margin: "0 auto" }} pt={2} >
                    <Typography color="error" align="center"  >
                        Infelizmente não conseguimos confirmar a impressão do seu pedido.
                           <br />
                        {getPerfile().tipo === "DELIVERY" ?
                            "Verifique com o restaurante."
                            :
                            "Verique com seu garçom."
                        }
                    </Typography>

                </Box>
            ) : null}


            {props.situacao === 'CONFIRMADO' || props.situacao === 'NAO_CONFIRMADO' ? (
                <BotaoWhatsApp visibility={ultimoPedido.tipoPedido === "DELIVERY"} mensagem={`Acabei de realizar o pedido ${ultimoPedido.identificadorSecundario} no qrpedir. `} />
            ) : null}


        </React.Fragment>
    )
}

export default Pedido;