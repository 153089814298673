import React, { Component } from "react";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    TextField,
    Dialog,
    Paper
} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    calcularFracaoParaComplementoCompleto,
    calcularValorTotalItemComComplemento,
    recalcularTotalItem,
    getCart,
    duplicarItemCompleto,
    calculaMaiorValorUnitario
} from '../../utils/PedidoUtil';
import Utils, { createUUID, isEmpresaBloqueada, removerPassosPausados } from '../../utils/Utils';
import AuthService from "../../services/AuthService";
import CardGenerico from '../generic/CardGenerico'
import CustomButton from '../generic/CustomButton'
import ImagemProduto from '../generic/ImagemProduto'
import Zoom from '@material-ui/core/Zoom';
import { withSnackbar } from "notistack";
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles'; 
import { withRouter } from 'react-router-dom';
import * as Pixel from '../../utils/FacebookPixelUtil';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const PaperDialog = React.forwardRef(function PaperDialog(props, ref) {
    const theme = useTheme();
    const backgroundColor = theme.palette.primary.main
    return <Paper style={{ backgroundColor: backgroundColor }}  {...props} />;
});
  
  
const loadDefaultStateToProduct = ( produtoParam ) => {

    let produto = produtoParam ? produtoParam : { complementos: [], grupoProduto: {tipoGrupoProduto: { imagem : "" }} }

    produto = JSON.parse(JSON.stringify( produto ));

    const complementos = removerPassosPausados( produto.complementos )

    return {
        produto: produto,
        item: {
            quantidade: 1,
            valorUnitario: produto ? produto.preco : 0.0, 
            valorTotal: produto ? produto.preco : 0.0,
            nomeCliente: '',
            descricao: '',
            valorDesconto: 0,
            produto: produto,
            ordem: 0,
        },
        tamanhoSelecionado: null,
        tela: {
            passoAtual: (complementos && complementos.length > 0 ? 0 : -1),
            complementos: complementos
        },
        animation: true,
        configuracaoAgendamento: null
    }
}


class ProdutoDialog extends Component {

    constructor(props) {
        super(props);

        this.state = loadDefaultStateToProduct(props.produto)

        this.avancarProximoPasso = this.avancarProximoPasso.bind(this)
        this.addSubitem = this.addSubitem.bind(this)
        this.onBackStep = this.onBackStep.bind(this)
        this.confirmarAdicaoItem = this.confirmarAdicaoItem.bind(this)
        this.handleVerificarTamanho = this.handleVerificarTamanho.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.reloadAnimation = this.reloadAnimation.bind(this)
        this.addAndRemove = this.addAndRemove.bind(this)
        this.buttonDisabledClick = this.buttonDisabledClick.bind(this)
        this.voltarAoPedido = this.voltarAoPedido.bind(this)

    }

    componentDidMount(){
        this.getConfigsAgendamento()
        window.addEventListener('popstate', this.voltarAoPedido, false);
    }

    componentWillUnmount(){
        window.removeEventListener('popstate', this.voltarAoPedido, false);
    }

    //ATUALIZA O STATE TODA VEZ QUE MUDAR O PRODUTO NO PROPS
    componentDidUpdate(prevProps){
        if (prevProps.produto.id !== this.props.produto.id){
            this.setState(loadDefaultStateToProduct(this.props.produto))
        }
    }
    
    reloadAnimation() {
        this.setState({ animation: false })
        setTimeout(() => { this.setState({ animation: true }) }, 200);
    }


    handleVerificarTamanho(item) {
        this.setState({ tamanhoSelecionado: item })

        const { complementos } = this.state.tela
        const complementoAtual = complementos[this.state.tela.passoAtual]
        complementoAtual.complementoItens.map(item => (
            item.quantidade = null
        ))

        item.quantidade = 1
    }


    voltarAoPedido(event) {
        //não aciona o voltar caso usuário clicou no 'voltar' do navegador
        let userClickBackBrowser = event && event.type === "popstate"
        if ( !userClickBackBrowser ){
            window.history.back()
        }

        this.props.handleClose()
    }

    addAndRemove(option) {
        const { item } = this.state;

        if (option === true) {
            item.quantidade = item.quantidade + 1;
        } else {
            if (item.quantidade > 1) {
                item.quantidade = item.quantidade - 1;
            }
        }
        recalcularTotalItem(item)
        this.setState({ item: item })
    }

    addAndRemoveQtdePrincipal(sinal) {
        const { item } = this.state;

        if (sinal === "+") {
            item.quantidadePrincipal = (item.quantidadePrincipal || 1) + 1;
        } else if (sinal === "-") {
            if (item.quantidadePrincipal > 1) {
                item.quantidadePrincipal = item.quantidadePrincipal - 1;
            }
        }
        recalcularTotalItem(item)
        this.setState({ item: item })
    }

    avancarProximoPasso() {
        let { tela } = this.state

        let qtdePassos = tela.complementos.length
        tela.passoAtual += 1

        /* CHEGOU NO ULTIMO PASSO */
        if (qtdePassos < tela.passoAtual + 1) {
            tela.passoAtual = -1
        }

        this.setState({ tela })
        this.reloadAnimation()
    }

    confirmarAdicaoItem() {
        let { tela } = this.state
        let { item } = this.state

        if (isEmpresaBloqueada()) {
            this.props.history.push("/errorSistemaBloqueado")
            return;
        }

        let orderDoSubItem = 0;
        let subItens = []

        const cart = getCart()

        if (cart.temItens()) {
            const { itens } = cart.pedido;
            const ultimoItem = itens[itens.length - 1]

            item.ordem = (ultimoItem.ordem || 0) + 1
        }

        tela.complementos.map(complemento => {
            /* FRACAO É VALIDA PRA CALCULAR PIZZAS PROPORCIONAIS */
            let { fracao, qtdeTotalItens } = calcularFracaoParaComplementoCompleto(complemento)

            const TIPO_PIZZA = complemento.tipoComplemento === "PIZZA_FRACIONADA" ||
                complemento.tipoComplemento === "PIZZA_MAIOR_VALOR"

            let fracaoTrunk = parseFloat(fracao.toFixed(2))
            let restoFracao = 1.0 - (fracaoTrunk * qtdeTotalItens);
            let acumuladoSubItem = 0;
            let acumuladoSubItenTrunc = 0;
            let lastElement = null

            complemento.complementoItens.map((complementoItem, index) => {
               
                if (complementoItem.quantidade && complementoItem.quantidade > 0) {

                    let quantidadeCalculada = complementoItem.quantidade
                    let valorUnitario = complementoItem.valor
                    let fracaoCalculada = fracaoTrunk;

                    if (TIPO_PIZZA) {
                        /* SE O RESTO FOR MAIOR QUE 0.01 */
                        if (Math.abs(restoFracao) >= 0.01) {
                            fracaoCalculada += restoFracao;
                            restoFracao = 0.0;
                        }

                        quantidadeCalculada = complementoItem.quantidade * fracaoCalculada
                        
                    }

                    if (complemento.tipoComplemento === "PIZZA_MAIOR_VALOR") {
                        valorUnitario = calculaMaiorValorUnitario(complemento);
                    }

                    const valorTotal = (quantidadeCalculada * valorUnitario).toFixed(2)

                    let subItem = {
                        uuid: createUUID(),
                        quantidade: quantidadeCalculada,
                        valorDesconto: 0,
                        valorUnitario: valorUnitario,
                        valorTotal: parseFloat(valorTotal),
                        descricao: complementoItem.descricao,
                        codigoExterno: complementoItem.codigoExterno,
                        descricaoPasso: complemento.descricao,
                        ordem: orderDoSubItem,
                        complementoItem: { id: complementoItem.id }
                    }

                    subItens.push(subItem)
                    orderDoSubItem++

                    if (TIPO_PIZZA) {
                        lastElement = subItem
                        acumuladoSubItem += parseFloat(quantidadeCalculada) * parseFloat(valorUnitario)
                        acumuladoSubItenTrunc += parseFloat(valorTotal)
                       
                    }
                }
            })

            const diferenca = (parseFloat(acumuladoSubItem) - parseFloat(acumuladoSubItenTrunc)).toFixed(2)

            if (lastElement){
                lastElement.valorTotal += parseFloat(diferenca)
            }
        })

        item.subItens = subItens

        this.setState({ item })

        //PASSO = -1 é PASSO FINAL
        if (tela.passoAtual === -1) {
            if (item.quantidadePrincipal && item.quantidadePrincipal > 1) {
                let qtdePrincipal = item.quantidadePrincipal
                for (var i = 0; i < qtdePrincipal; i++) {
                    let itemDuplicado = duplicarItemCompleto(item)
                    getCart().adicionarItemPedidoCorrente(itemDuplicado)
                }
            } else {
                getCart().adicionarItemPedidoCorrente(item)
            }

            if (Pixel.isPixelOn()) {
                let pixelObj = {
                    value: item.valorTotal,
                    content_name: item.produto.nome,
                    currency: 'BRL',
                }
                Pixel.trackCustom('AddToCart', pixelObj)
            }

            this.voltarAoPedido()
        }

    }

    permiteAdicionar(complementoAtual) {
        if (!complementoAtual) {
            return true
        }
        let max = complementoAtual.max
        let qtdeSelecionada = 0
        complementoAtual.complementoItens.map(complementoItem => {
            if (complementoItem.quantidade)
                qtdeSelecionada += complementoItem.quantidade
        })
        return max > qtdeSelecionada
    }

    condicaoSatisfeitaComplemento(complementoAtual) {

        if (complementoAtual === undefined)
            return true

        //se nao tiver complementos, retorna true
        if (!complementoAtual.complementoItens)
            return true

        if (complementoAtual.complementoItens.length === 0)
            return true

        let max = complementoAtual.max
        let min = complementoAtual.min
        let obrigatorio = complementoAtual.obrigatorio

        let qtdeSelecionada = 0
        complementoAtual.complementoItens.map(complementoItem => {
            if (complementoItem.quantidade)
                qtdeSelecionada += complementoItem.quantidade
        })
        if (obrigatorio) {
            return max >= qtdeSelecionada && qtdeSelecionada >= min && qtdeSelecionada > 0
        } else {
            return max >= qtdeSelecionada && (qtdeSelecionada === 0 || min <= qtdeSelecionada)
        }
    }

    addSubitem(itemComplemento) {
        let { tela } = this.state
        const { complementos } = tela
        const complementoAtual = complementos[tela.passoAtual]

        if (!this.permiteAdicionar(complementoAtual)) {
            return
        }

        if (itemComplemento.quantidade) {
            itemComplemento.quantidade += 1
        } else {
            itemComplemento.quantidade = 1
        }
        this.setState({ tela })
    }

    removeSubItem(itemComplemento) {
        if (itemComplemento.quantidade === 0) {
            itemComplemento.quantidade = 0
        } else {
            itemComplemento.quantidade -= 1
        }
        let { tela } = this.state
        this.setState({ tela })
    }

    onBackStep() {
        let { tela } = this.state
        if (tela.passoAtual > 0) {
            tela.passoAtual -= 1
            this.reloadAnimation()
        } else {
            if (tela.complementos.length > 0 && tela.passoAtual === -1) {
                tela.passoAtual = tela.complementos.length - 1
                this.reloadAnimation()
            } else {
                this.voltarAoPedido()
            }
        }
        this.setState({ tela })
    }

    buttonDisabledClick() {
        const { tela } = this.state
        const { complementos } = tela
        const complementoAtual = complementos[tela.passoAtual]
        const condicaoSatisfeita = this.condicaoSatisfeitaComplemento(complementoAtual)

        if (!condicaoSatisfeita) {
            let msg = ""
            if (complementoAtual.tipoComplemento === 'TAMANHO') {
                msg = "Selecione um tamanho"
            } else {
                msg = `A quantidade mínima é ${complementoAtual.min}`
            }
            this.props.enqueueSnackbar(msg,
                {
                    preventDuplicate: true,
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
        }
    }


    handleChange( event ) {
        const { item } = this.state;
        item[event.target.name] = event.target.value
        this.setState({ item })
    };

    getConfigsAgendamento = () => {
        var service = new AuthService();

        service
            .get("/configurarAgendamento/configuracaoAtual")
            .then(resp => {
                this.setState({ configuracaoAgendamento: resp });
            })
    }

    render() {
        const { item, produto, tela, animation, configuracaoAgendamento } = this.state
        const complementos = tela.complementos
        const complementoAtual = complementos[tela.passoAtual]
        const condicaoSatisfeita = this.condicaoSatisfeitaComplemento(complementoAtual)
        const permiteAdicionar = this.permiteAdicionar(complementoAtual)
        const statusLoja = AuthService._statusLoja()

        const ultimoPasso = tela.passoAtual === -1

        let tipoTela = "PASSO_FINAL"
        if (complementoAtual) {
            if (complementoAtual.tipoComplemento === 'TAMANHO') {
                tipoTela = "TAMANHO"
            }
            if (complementoAtual.tipoComplemento !== 'TAMANHO') {
                tipoTela = "PASSOS"
            }
        }

        let styles = {
            total: {
                fontFamily: "Bebas Neue",
                fontSize: "18px",
                fontWeight: "500",
                textTransform: "uppercase",
                marginLeft: "15px"
            }
        }

        return (
            
        <Dialog open={this.props.open} fullScreen={true}  TransitionComponent={Transition} PaperComponent={PaperDialog}>
             
            <CardGenerico
                leftButtonClick={this.onBackStep}
                rightButtonClick={() => this.voltarAoPedido()}
                cardMedia={
                    <ImagemProduto produto={produto} />
                }
                cardContent={
                    <React.Fragment>
                        {tipoTela === 'TAMANHO' ? (
                            <Zoom in={animation}>
                                <div>
                                    <Box pt={2} pb={2}>  </Box>
                                    <Grid container direction="row" justify="center" alignItems="center" key={item.id}>
                                        <Typography style={{ fontFamily: "Bebas Neue" }} color="primary" variant="h6" component="h3">
                                            Escolha o tamanho
                                        </Typography>
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            {complementoAtual.complementoItens.map(item => (
                                                item.pausado ? null
                                                    : (
                                                        <FormControl component="fieldset" key={item.id}>
                                                            <RadioGroup
                                                                value={this.state.tamanhoSelecionado || ''}
                                                                onChange={() => { this.handleVerificarTamanho(item) }}
                                                                name="tamanhoSelecionado">
                                                                <FormControlLabel
                                                                    value={item}
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <React.Fragment>
                                                                            <Typography style={{ fontFamily: "Bebas Neue" }} color="primary"> {item.descricao} </Typography>
                                                                            <Typography color='secondary' style={{ fontFamily: "Bebas Neue" }}>{Utils.formatNumber(item.valor)} </Typography>
                                                                        </React.Fragment>
                                                                    }
                                                                    labelPlacement="bottom"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )
                                            ))}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Zoom>
                        ) : null}

                        {tipoTela === 'PASSOS' ? (
                            <Zoom in={animation}>
                                <div>

                                    <Grid container direction="row">
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            <Typography style={{ fontFamily: "Bebas Neue" }} color="primary" variant="h6" component="h3" >
                                                {complementoAtual.descricao}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ textAlign: 'center' }} xs={12}>
                                            <Typography variant="caption" display="block" color="primary"
                                                style={{ opacity: (condicaoSatisfeita ? "0.4" : "1") }} >
                                                {complementoAtual.obrigatorio ? '* ' : null} Mínimo {complementoAtual.min} e máximo {complementoAtual.max}
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <div >
                                                {complementoAtual.complementoItens.map(item => (
                                                    item.pausado ? null : (
                                                        <Box key={item.id} display="flex" alignItems="center">
                                                            <Box flexGrow={1} >
                                                                <strong style={{ textTransform: 'uppercase', fontSize: "80%" }}> {item.descricao} </strong>

                                                                {item.descricaoComplementar ? (
                                                                    <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
                                                                        {item.descricaoComplementar}
                                                                    </Typography>
                                                                ) : null}

                                                            </Box>

                                                            <Box display="flex" alignItems="center">
                                                                <Box>
                                                                    <Typography variant="caption" >{Utils.formatNumber(item.valor)}</Typography>
                                                                </Box>

                                                                <Box style={{ whiteSpace: "nowrap" }} >
                                                                    {item.quantidade ?
                                                                        <IconButton onClick={() => this.removeSubItem(item)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                        : null}
                                                                    <strong>
                                                                        {item.quantidade === 0 ? null : item.quantidade}
                                                                    </strong>
                                                                    <IconButton onClick={() => this.addSubitem(item)} disabled={!permiteAdicionar}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                ))}
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </Zoom>
                        ) : null}

                        {tipoTela === 'PASSO_FINAL' ? (

                            <React.Fragment>
                                <Zoom in={animation}>
                                    <div>
                                        <Box p={2}>

                                            <Box display="flex" alignItems="center" pt={1} pb={1}>
                                                <Box flexGrow={1}>
                                                    <strong > quantidade </strong>
                                                </Box>

                                                {!produto.complementos.length ? (
                                                    <Box>
                                                        <IconButton disabled={item.quantidade < 2} onClick={() => this.addAndRemove(false)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        <strong style={{ padding: "0 10px 0 10px" }}>{item.quantidade}</strong>
                                                        <IconButton onClick={() => this.addAndRemove(true)} >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                ) : (
                                                        <Box>
                                                            <IconButton disabled={!item.quantidadePrincipal || item.quantidadePrincipal < 2} onClick={() => this.addAndRemoveQtdePrincipal("-")}>
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <strong style={{ padding: "0 10px 0 10px" }}>{item.quantidadePrincipal || 1}</strong>
                                                            <IconButton onClick={() => this.addAndRemoveQtdePrincipal("+")} >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Box>
                                                    )}

                                            </Box>

                                        </Box>

                                        <Box >

                                            <TextField
                                                label="Alguma observação?"
                                                placeholder="Ex: tirar cebola, maionese à parte.."
                                                multiline
                                                rows="1"
                                                name="observacao"
                                                value={item.observacao}
                                                onChange={this.handleChange}
                                                fullWidth variant="outlined" />
                                        </Box>

                                    </div>
                                </Zoom>
                            </React.Fragment>

                        ) : null}

                    </React.Fragment>

                }

                cardActions={
                    <Box display="block" textAlign="center">
                        <Typography style={styles.total} color="primary">
                            {`TOTAL ${Utils.formatNumber(calcularValorTotalItemComComplemento(item, complementos))}`}
                        </Typography>

                        {configuracaoAgendamento?.situacao === "N" && statusLoja === "FECHADO" ? (
                            <CustomButton
                                disabled="true"
                                variant="contained"
                                color="secondary"
                                fullWidth >Que pena, estamos fechados
                            </CustomButton>
                        ) : (
                            <React.Fragment>
                                <div onClick={this.buttonDisabledClick}>
                                    {ultimoPasso ?
                                        <CustomButton
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            disabled={!condicaoSatisfeita}
                                            onClick={this.confirmarAdicaoItem}
                                            endIcon={
                                                <DoneIcon />
                                            }>
                                            Adicionar produto
                                        </CustomButton>
                                        :
                                        <CustomButton
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            disabled={!condicaoSatisfeita}
                                            endIcon={
                                                <KeyboardArrowRightIcon />
                                            }
                                            onClick={this.avancarProximoPasso}>
                                            Próximo passo
                                            </CustomButton>
                                    }
                                </div>
                            </React.Fragment>
                        )}

                    </Box>
                }
            />
             
            </Dialog>
        );
    }
}

export default withRouter( withSnackbar(ProdutoDialog))


 