import React from "react";
import AuthService from "../../services/AuthService";


function TempoPreparo(props) {

    const [tempoMinPreparoText, setTempoMinPreparoText] = React.useState("carregando...");

    React.useEffect(() => {
        let idEmpresa = AuthService.getProfile().empresa.id

        let service = new AuthService()
        service
            .get(`/empresa/getOnly/${idEmpresa}?atributos=tempoMinPreparo,tempoMaxPreparo`)
            .then(resp => {
                let tempoMinPreparo = resp.tempoMinPreparo
                let tempoMaxPreparo = resp.tempoMaxPreparo

                setTempoMinPreparoText( `Tempo de entrega ${tempoMinPreparo}m - ${tempoMaxPreparo}m`)
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    return (
        <React.Fragment {...props}>
           {tempoMinPreparoText}
        </React.Fragment>
    )
}

export default TempoPreparo;
