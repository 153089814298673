import React from "react";
import AuthService from "../../services/AuthService";
import { Box, Typography  } from "@material-ui/core";
import HorarioAbertura from "./HorarioAbertura";

export default function StatusLoja(props) {
    const [open, setOpen] = React.useState(false);
    const status = AuthService._statusLoja()
    const dense = Boolean(props.dense)

    const handleChangeDialog = () => {
        setOpen(!open);
    }

    return (
        <React.Fragment>
            <HorarioAbertura open={open} handleChangeDialog={handleChangeDialog} />
            {status === "ABERTO" ? (
                <Box display="flex" size="small">
                    <Typography style={{color: "#66bb6a"}} 
                        variant="body2" color="textSecondary" 
                        onClick={handleChangeDialog}>
                        aberto agora
                    </Typography>
                </Box> 
            ) : (
                <React.Fragment>
                <Box display="flex" >
                    <Typography variant="body2" 
                        color="error"
                        onClick={handleChangeDialog}>
                        {dense ? "fechado" : "Estamos fechados"}
                    </Typography>
                </Box>
             
               </React.Fragment>
            )}

            
        </React.Fragment>
    )
}

