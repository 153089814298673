import React from "react";
import { Typography, Box, ListItemAvatar, Avatar, Grid, ButtonBase  } from "@material-ui/core";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Utils, { formatarReal, trunkStr } from '../../../utils/Utils';
import PromoChip from '../../generic/PromoChip'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    large: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },

    button: {
        //backgroundColor: 'rgba(255, 255, 255)', 
        backgroundColor: theme.palette.personal.paperBackground,
        
        //backgroundColor: theme.palette.personal.backgroundColor,
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        minHeight: "70px",
        maxHeigth: "100px",
        width: '100%',
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
            backgroundColor: 'transparent',
            },
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundClip: 'padding-box',
    },

    listProdutos: {
       
        width: '100%',
        maxHeigth: "100px"
    },
    item: {
        fontFamily: "Bebas Neue",
        fontSize: "22px",
        fontWeight: "500"
    },
    valor: {
        fontFamily: "Bebas Neue",
        fontSize: "22px",
        fontWeight: "500",
        color: theme.palette.primary.main,
        whiteSpace: "nowrap"
    },
    real: {
        fontSize: "70%",
        opacity: "0.5"
    },

    boxItem: {
        
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            margingBotton: "1px solid black"
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        
    }

  }));


function GrupoProdutoResponsivoList({ parentGrupo, setProdutoSelecionado}){
    
    const classes = useStyles();

    function handlePedido(produto) {
        setProdutoSelecionado(produto)
    }
    
    return (
        <React.Fragment>

       <Box>

        <Grid container >
        
        {parentGrupo.produtos.map((produto, pos, array) => (
            <Grid xs={12} sm={6} key={produto.id} item>
                <Box className={classes.boxItem}>
                <ButtonBase focusRipple  
                    className={classes.button}
                    onClick={() => handlePedido(produto)} >
                    <Box  display="flex"
                        px={2} py={1} 
                        className={classes.listProdutos}
                         >

                         <Box >
                            {produto.imagens && produto.imagens.length > 0 ? (
                                <ListItemAvatar >
                                <Avatar variant="rounded"  src={ produto.imagens[0].thumbnailUrl }  className={classes.large}/>
                                </ListItemAvatar>
                            ): (
                            null
                            )}
                         </Box>
                         
                         <Box px={1} 
                            flexGrow={1}
                            textAlign="initial"
                            alignItems="center" >

                            <Typography
                                variant='body1'
                                component="span"
                                display="block" >
                                <span className={classes.item} >
                                    {produto.nome} {produto.promocaoAtiva ? <PromoChip/> : ''}
                                </span>
                            </Typography>

                            {produto.descricao ? (
                                <Typography
                                variant="body2"
                                color="textSecondary"
                                display="block"
                                className={classes.itemDescricao}>
                                { trunkStr(produto.descricao, 60)}
                                </Typography>
                            ) : null}
                           
 
                         </Box>

                         <Box p={1} >
                         <Box display="flex" alignItems="rigth">
                                    {produto.preco === 0.0 ?
                                        <DoubleArrowIcon className={classes.valor} /> 
                                        :
                                        <Box flexGrow={1}>
                                            <Typography className={classes.valor} >
                                                  { Utils.formatNumber(produto.preco) }
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                        </Box>

                        
                    </Box>
                </ButtonBase>
                </Box>
            </Grid>
        ))}
        </Grid>

        </Box> 
           
        </React.Fragment>
    );
}

export default GrupoProdutoResponsivoList;



