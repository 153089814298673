import React, { useEffect } from "react";
import { getCart } from '../../utils/PedidoUtil'
import { Snackbar, Slide, Badge, Button, SnackbarContent, Typography, Box, Paper } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { getPerfile } from "../../utils/PedidoUtil";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  message: {
    padding: 0,
  },
}));

function Cart( {handleClickOnCart} ) {
  
  const classes = useStyles();
  const cartOpened = useSelector(state => state.cart.open)

  useEffect(() => {
    console.log('Iniciando carrinho')
    handleOpenCart();
  }, [])

  const [state, setState] = React.useState({
    Transition: SlideTransition,
  });

  const handleOpenCart = () => {
    setState({
        Transition: SlideTransition,
    });
  };


  const handleClick = () => {
    if (getCart().temItens() > 0) {
      handleClickOnCart()
    }
  }

  let profile = JSON.parse(localStorage.getItem("_profile"));
  let identificador = profile.tipo;
  let isSequencialOrDelivery = profile.tipo === "SEQUENCIAL" || profile.tipo === "DELIVERY";

  return (

    <React.Fragment>
      {isSequencialOrDelivery && (getCart().pedido.itens.length > 0) || !isSequencialOrDelivery ?
        <Snackbar
          onClick={handleClick}
          open={cartOpened}
          TransitionComponent={state.Transition}
          ContentProps={{
            'aria-describedby': 'message-id'
          }} >
          {getCart().temItens()  ?
            <SnackbarContent key="SnackbarContent" style={{ backgroundColor: '#FFFFFF' }}
            classes={{
              message: classes.message,
            }}
              message={
                <React.Fragment>
                  <IconButton key="close" aria-label="close" color="inherit" onClick={handleClick}>
                    <Badge badgeContent={getCart().pedido.itens.length} color="primary">
                      <ShoppingCartOutlinedIcon color="primary"/>
                    </Badge>
                  </IconButton>
                  <Button id="message-id" onClick={handleClick}>
                    <Typography color="primary" style={{ fontFamily: "Bebas Neue", fontSize: "23px"}}>
                      Finalizar meu pedido
                </Typography>
                  </Button>
                </React.Fragment>
              }
              action={[
                <Typography key="identificador-label" 
                  color="primary"
                  style={{ lineHeight: "1.2", textAlign: "center", marginRight: "7px", fontFamily:  "Bebas Neue", fontSize: "18px" }}>
                  {identificador !== "DELIVERY" ? (
                    <React.Fragment>
                      {identificador} <br />  { isSequencialOrDelivery ? "" : getPerfile().numero }
                    </React.Fragment>
                  ) : null}
                 
                </Typography>
              ]}
            /> :
            <Box key="SnackbarContent-box" zIndex="modal"  style={{  minWidth: "70px", display: "flex", justifyContent: "center", padding: "10px", position: "fixed", bottom: "0", right: "0", whiteSpace: "nowrap" }}>
              <Paper >
                <Box display="block" pt={1} pb={1} pl={2} pr={2}>
                  <Typography color="primary" style={{ fontFamily: "Bebas Neue", fontSize: "23px" }}>
                  {identificador} { isSequencialOrDelivery ? "" : getPerfile().numero }
                  </Typography>
                </Box>
              </Paper>
            </Box>
          }
        </Snackbar>
        : null}
    </React.Fragment>
  )
}


export default Cart;
