import React, { useState } from 'react'
import {
  DialogTitle,
  Box,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { generate_qrcp } from '../../../utils/Gerar_QR'
var QR_Code_Img = require('qrcode.react');
// npm install qrcode.react para gerar um QR Code
// a partir de uma string ou outros valores

function QR_Code(props) {
  var base64 = ''; // variavel global que salva o valor base 64 utilizado para gerar o pagamento pix
  // copia e cola e o pagamento via QR Code
  const [QRCode, setQRCode] = useState(false);

  const { pedido, total, ...other } = props;

  const { enqueueSnackbar } = useSnackbar();

  // http://localhost:3001/infoPedido/354100

  const handleGerarQRCode = (formaPagamentoObj, valor) => { // recebe o objeto formaPagamento selecionado no campo
    // para utilizar suas informações e criar o QR Code 
    //let cart = getCart() // pega as informaçãoes de compra para calcular o valor total da compra

    var chavePix = String(formaPagamentoObj.chavePix) // recebe da forma de pagamento a chave Pix cadastrada
    var tipoChave = String(formaPagamentoObj.tipoChave)// recebe da forma de pagamento o tipo de chave Pix cadastrada
    var nomeBeneficiario = String(formaPagamentoObj.nomeBeneficiario)// recebe da forma de pagamento o nome do beneficiário cadastrado
    var cidadeBeneficiario = String(formaPagamentoObj.cidadeBeneficiario)// recebe da forma de pagamento a cidade do beneficiário cadastrado
    //var valor = String(calcularValorTotalPedido(cart.pedido))// calcula o valor total do pedido para ser inserido no pagamento
    var amount = String(valor)

    base64 = generate_qrcp(chavePix, tipoChave, amount, nomeBeneficiario, cidadeBeneficiario)

    // salva na variável global base64 o código no formato base 64 vindo da função de gerar o código de QR
    // para pagamento via pix copia e cola

  }

  const handleQRCode = (formaPagamentoObj, valor) => {// recebe o objeto formaPagamento selecionado no campo
    setQRCode(true)
    //deixa ativo o Dialog do campo QR code

    handleGerarQRCode(formaPagamentoObj, valor)// envia para a função de criar QR Code a 
    //forma de pagamento selecionada
  }

  const handleCloseQRCode = () => { // deixa desativado o Dialog do QR Code
    setQRCode(false)
  }

  const onClickHandleCopyToClipboard = () => { //copia o código para o clip board
    navigator.clipboard.writeText(base64)
    enqueueSnackbar('Código copiado com sucesso.', { variant: "success" });
  }

  const onClickHandleShowQRCode = () => {
    setQRCode(true)
  }

  return (

    <section>
      {handleGerarQRCode(pedido, total)}

      <Button variant="outlined" size='small' onClick={onClickHandleShowQRCode}>Mostrar QR Code</Button>

      <Dialog
        open={QRCode}
        onClose={handleCloseQRCode}
        align='center'
        maxWidth="xs"
        fullWidth
      >
        <Grid container >
          <Grid item xs={10}>
            <DialogTitle >
              Pague agora com Pix
            </DialogTitle >
          </Grid>
          <Grid item xs={2} >
            <IconButton color="primary" onClick={handleCloseQRCode}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>


        <DialogContent>

          <Box display="flex" justifyContent="center">

            <QR_Code_Img size={256} value={base64} renderAs='svg' onClick={onClickHandleCopyToClipboard} />
          </Box>

        </DialogContent>

        <DialogActions>

          <Button
            onClick={onClickHandleCopyToClipboard}>
            <LibraryBooksIcon /> <strong style={{ marginLeft: "10px" }}>
              Copiar Código </strong>
          </Button>
        </DialogActions>
      </Dialog>

    </section>
  )
}

function CodigoCopiaECola(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { pedido, total, ...other } = props;

  var base64 = ''; // variavel global que salva o valor base 64 utilizado para gerar o pagamento pix
  // copia e cola e o pagamento via QR Code

  const handleGerarCode = (formaPagamentoObj, valor) => { // recebe o objeto formaPagamento selecionado no campo
    // para utilizar suas informações e criar o QR Code 
    //let cart = getCart() // pega as informaçãoes de compra para calcular o valor total da compra

    var chavePix = String(formaPagamentoObj.chavePix) // recebe da forma de pagamento a chave Pix cadastrada
    var tipoChave = String(formaPagamentoObj.tipoChave)// recebe da forma de pagamento o tipo de chave Pix cadastrada
    var nomeBeneficiario = String(formaPagamentoObj.nomeBeneficiario)// recebe da forma de pagamento o nome do beneficiário cadastrado
    var cidadeBeneficiario = String(formaPagamentoObj.cidadeBeneficiario)// recebe da forma de pagamento a cidade do beneficiário cadastrado
    //var valor = String(calcularValorTotalPedido(cart.pedido))// calcula o valor total do pedido para ser inserido no pagamento
    var amount = String(valor)

    base64 = generate_qrcp(chavePix, tipoChave, amount, nomeBeneficiario, cidadeBeneficiario)

    // salva na variável global base64 o código no formato base 64 vindo da função de gerar o código de QR
    // para pagamento via pix copia e cola

  }

  const onClickHandleCopyToClipboard = () => { //copia o código para o clip board
    navigator.clipboard.writeText(base64)

    enqueueSnackbar('Código copiado com sucesso.', { variant: "success" });
  }

  return (
    <section>
      {handleGerarCode(pedido, total)}

      <Button onClick={onClickHandleCopyToClipboard}
        variant="contained" color="primary">
        <LibraryBooksIcon /> <strong style={{ marginLeft: "10px" }}>
          Copiar Código PIX </strong>  </Button>
    </section>
  )
}

export { QR_Code, CodigoCopiaECola }