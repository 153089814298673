import React from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { savePessoa } from "../../../utils/PedidoUtil";
import { onlyDigits } from "../../../utils/Utils";
import CustomizedDialog from "../../generic/CustomizedDialog"
import MaskedInput from 'react-text-mask';
import CustomButton from '../../generic/CustomButton'


const TextMaskCPF = (props) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.',  /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-' ,/\d/, /\d/]}
        placeholderChar={'\u2000'}
        placeholder="xxx.xxx.xxx-xx"
      />
    );
  }

const TextMaskPhone = (props) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        placeholder="(xx) x xxxx-xxxx"
      />
    );
  }

export default function DadosPicPay({ pessoa, open, handleClose, confirmarDados}){
    
    const [values, setValues] = React.useState({
        firstName: pessoa.nome || "",
        lastName: pessoa.sobrenome || "",
        document: pessoa.cpfCnpj || "",
        email: pessoa.email || "",
        phone: pessoa.telefone || ""
    });
    let [validacoes, setValidacoes] = React.useState({})
 

    const handleChange = (event, ...others ) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
      };

    const confirmar = () =>{
        let dadosValidos = validarDados()
        console.log("DADOS PICPAY SÃO VALIDOS?", dadosValidos)
        if (dadosValidos){

            pessoa.nome = values.firstName
            pessoa.sobrenome = values.lastName
            pessoa.cpfCnpj = values.document
            pessoa.email = values.email
            pessoa.telefone = values.phone
            savePessoa(pessoa)
            confirmarDados(pessoa)
        }
    }

    const validarDados = () => {
        let valido = true
        //limpa validações
        let validacoes = {}
        

        if (!values.firstName){
            validacoes = { ...validacoes, firstName: "É obrigatório" }
            valido = false
        }

        if (!values.lastName){
            validacoes = { ...validacoes, lastName: "É obrigatório" }
            valido = false
        }

        if (!values.document){
            validacoes = { ...validacoes, document: "É obrigatório" }
            valido = false
        }

        let doc = onlyDigits(values.document || "")
        if (doc.length != 11){
            validacoes = { ...validacoes, document: "CPF inválido (deve ter 11 dígitos)" }
            valido = false
        }

        if (!values.email){
            validacoes = { ...validacoes, email: "É obrigatório" }
            valido = false
        }

        if (!values.phone){
            validacoes = { ...validacoes, phone: "É obrigatório" }
            valido = false
        }

        let phoneOnlyDigits = onlyDigits(values.phone || "")
        if (phoneOnlyDigits.length < 10){
            validacoes = { ...validacoes, phone: "Deve ter pelo menos 11 dígitos" }
            valido = false
        }
        setValidacoes(validacoes)
        return valido
    }
      

    return(
        <div>
        <CustomizedDialog open={open}  
             handleClose={handleClose}
             responsive={true}
             title="Confirme seus dados"
             subtitle={"Informe o mesmo CPF cadastrado no seu PicPay"}
             dialogActions={
                <CustomButton 
                color="primary"
                variant="contained"
                fullWidth
                onClick={confirmar}
                >Confirmar</CustomButton>
             }>
            <div>
            <Grid
            container
            direction="row"
            spacing={2}>
                <Grid item xs={12} >
                    <TextField
                        label="Nome"
                        name="firstName"
                        value={values.firstName}
                        size="small"
                        fullWidth
                        required
                        error={!!validacoes.firstName}
                        inputProps={{ maxLength: "80" }}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} >
                    <TextField
                        label="Sobrenome"
                        name="lastName"
                        value={values.lastName}
                        size="small"
                        required
                        fullWidth
                        error={!!validacoes.lastName}
                        helperText={validacoes.lastName}
                        inputProps={{ maxLength: "80" }}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} >
                    <TextField
                        label="CPF"
                        name="document"
                        required
                        error={!!validacoes.document}
                        helperText={validacoes.document}
                        value={values.document}
                        size="small"
                        fullWidth
                        InputProps={{
                            inputComponent: TextMaskCPF
                        }}
                        onBlur={handleChange}
                        variant="outlined"
                    />

                </Grid>

                
                <Grid item xs={12} >
                    <TextField
                        label="E-mail"
                        name="email"
                        required
                        value={values.email}
                        size="small"
                        type="email"
                        fullWidth
                        error={!!validacoes.email}
                        helperText={validacoes.email}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>

                
                <Grid item xs={12} >
                    <TextField
                        label="Telefone"
                        name="phone"
                        required
                        value={values.phone}
                        fullWidth
                        size="small"
                        type="tel"
                        placeholder="Ex: (046) 9 9999 9999"
                        error={!!validacoes.phone}
                        helperText={validacoes.phone}
                        InputProps={{
                            inputComponent: TextMaskPhone
                        }}
                        onBlur={handleChange}
                        variant="outlined"
                    />
                </Grid>
 
            </Grid>
            </div>
        </CustomizedDialog>
        
        </div>
    )

}