import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    root: {
        fontFamily: "Bebas Neue", 
        fontSize: "1.2rem", 
        //color: "#253257"
    },
}

export default withStyles(styles)(Button);
