import {
    Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogContent,
    List, IconButton, ListItem, ListItemIcon, ListItemText, 
    makeStyles, Typography, withStyles
} from '@material-ui/core';
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AuthService from '../../../../services/AuthService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function MunicipioDasLojas({ open, setOpen, setLocation }) {
    const classes = useStyles();
    const service = new AuthService();
    const [municipioLojas, setMunicipioLojas] = React.useState([])
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
            color: theme.palette.grey[500],
        },
    });



    useEffect(() => {
        service.getNoToken(`/empresa/listLojas/municipios`)
            .then(retorno => {
                setMunicipioLojas(retorno)
            })
            .catch(err => {
                console.error(err)
            });
    }, [])

    const onClose = () => {
        setOpen(false)
    }

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Box display='flex'>
                    <Box flexGrow={2}>
                        <Typography color='primary' align='center' variant="subtitle2">{children}</Typography>
                    </Box>

                    <Box>
                        {onClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </Box>
                </Box>
            </MuiDialogTitle>
        );
    });

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>

            <DialogTitle onClose={onClose} >
                Hei... Aonde você está?
            </DialogTitle>

            <DialogContent>
                {municipioLojas && municipioLojas.map((eml, index) => (
                    // <div className={classes.root}>
                    <Accordion key={index} expanded={expanded === eml.estado} onChange={handleChange(eml.estado)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color='primary' className={classes.heading} >
                                {eml.estado}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <List component="nav" disablePadding dense>
                                {eml.municipioList.map((city, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => { setLocation({ city, state: eml.estado }); setOpen(false) }}
                                    >
                                        <ListItemIcon>
                                            <CheckIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={city} />
                                    </ListItem>
                                ))}

                            </List>
                        </AccordionDetails>
                    </Accordion>
                    // </div>
                ))}
            </DialogContent>
        </Dialog>
    );
}