import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core";
import DetItemTop from "./components/detItemTop";
import AuthService from "../../services/AuthService";
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from "notistack";
import CardGenerico from '../generic/CardGenerico'
import CustomButton from '../generic/CustomButton'

class Chamados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoChamado: [],
            optionsSelecionado: {},
            enviando: false
        }
        this.handlesetOptions = this.handlesetOptions.bind(this)
        this.handleChamado = this.handleChamado.bind(this)
    }

    componentDidMount() {
        this.handleList()
    }

    handleList() {
        let service = new AuthService();
        service
            .get("/tipoChamado/list")
            .then(retorno => {
                this.setState({ tipoChamado: retorno })
            })
            .catch(err => {
                this.props.history.replace("/errors");
            });
    }


    handlesetOptions(event, option) {
        const { optionsSelecionado } = this.state
        this.setState({ optionsSelecionado: option })
    }

    handleChamado() {
        const { optionsSelecionado } = this.state

        let profile = JSON.parse(localStorage.getItem("_profile"));
        let identificador = profile.tipo;
        let numeroIdentificador = parseInt(profile.numero);

        console.log("IDENTIFICADOR  " + numeroIdentificador);

        let service = new AuthService();
        let chamado = {
            descricao: optionsSelecionado.descricao,
            tipoChamado: optionsSelecionado,
            identificador: identificador,
            numeroIdentificador: numeroIdentificador
        }


        if (identificador !== "MESA") {
            JSON.stringify(chamado)
            this.props.history.push({
                pathname: `/dadosMesa`,
                search: '',
                state: { chamado: chamado }
            })

        } else {
            this.setState({ enviando: true })

            service
                .post("/chamado", chamado)
                .then(retorno => {

                    this.setState({ enviando: false })

                    if (retorno.id) {
                        this.props.history.push({
                            pathname: `/closeChamado`,
                            search: '',
                            state: { chamado: retorno }
                        })
                    } else {
                        this.props.enqueueSnackbar("Ops, não conseguimos chamar o garçom.", { variant: "warning" });
                    }

                }).catch(err => {
                    console.log('Erro',err)
                    this.props.enqueueSnackbar("Falha ao chamar o garçom", { variant: "error" });
                    this.setState({ enviando: false })
                })
        }
    }

    render() {
        let styles = {
            botao: {
                fontFamily: "Bebas Neue",
                fontSize: "25px",
                fontWeight: "400",
                color: '#253257'
            }
        }

        const { tipoChamado, enviando } = this.state
        return (
            <React.Fragment>

            <CardGenerico
                leftButtonClick={() => window.history.back()}
                cardMedia={
                    <DetItemTop />
                }
                cardContent={
                    tipoChamado.filter(elem => !!elem.pausado).map(option => (

                        <Box key={option.id} pt={1}>
                            <Paper variant="outlined">

                                <RadioGroup value={this.state.optionsSelecionado || ''}
                                    onChange={(event) => { this.handlesetOptions(event, option) }}
                                    name="selecionaOptions"
                                    style={{ width: "100%", padding: "10px" }}>
                                    <FormControlLabel
                                        value={option}
                                        label={
                                            <Typography color="primary" style={{ fontFamily: "Bebas Neue", fontSize: '22px' }}>{option.descricao}</Typography>
                                        }
                                        control={<Radio color="primary" />}
                                    />
                                </RadioGroup>
                            </Paper>
                        </Box>

                    ))
                }
                cardActions={
                    <CustomButton
                    variant="contained" color="secondary" fullWidth
                    onClick={this.handleChamado}
                    disabled={!this.state.optionsSelecionado.id || (enviando)}>
                        {enviando ? 'Chamando...' : 'Chamar garçom'}
                    </CustomButton>
                }
            >
            </CardGenerico>

            </React.Fragment>
        );
    }
}

export default withSnackbar(Chamados);



