import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    appBar: {
        transition:  theme.transitions.create('all', {
                duration: theme.transitions.duration.standard,
            })
    },
    appBarStatic: {
        border: '0px',
        //background: 'rgba(255, 255, 255)',
        backgroundColor: theme.palette.personal.paperBackground,
    },
    appBarFixed: {
        marginTop: '48px',
        border: '0px',
        backgroundColor: theme.palette.personal.paperBackground,
    }
}));

function GrupoList({ onClick, grupos, activeIndex, buscandoProdutos }) {

    const classes = useStyles();
    const [grupoList, setGrupoList] = React.useState([])
    const [value, setValue] = React.useState(0);
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 400 });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setGrupoList(grupos)
    }, [grupos])

    useEffect(() => {
        if (activeIndex){
            setValue(activeIndex)
        }
    }, [activeIndex])


    return (
        <div style={{ minHeight: '48' }}>
            {buscandoProdutos ? <SkeletonListGroups /> : (
     
                    <AppBar 
                        variant='outlined'
                        position={trigger ? 'fixed' : 'static'} 
                        color='default' 
                        className={clsx(classes.appBar, trigger ? classes.appBarFixed : classes.appBarStatic ) } >
                        
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {
                                grupoList.map((grupo, index) => (
                                    <Tab key={index} 
                                        color='primary' 
                                        label={grupo.grupoProduto.descricao} 
                                        onClick={() => onClick(grupo.grupoProduto.id)} 
                                        wrapped={true} />
                                ))
                            }
                        </Tabs>
                    </AppBar>
               
                )  }
        </div>
    );
}

export default GrupoList;

 

function SkeletonListGroups() {
    return (
        <Skeleton variant="rect" height={48} />
    );
}