import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import Info from "../../imagens/IconeRoboGarconDormindo.svg";
import Button from '@material-ui/core/Button';
import AvisoGenerico from "../generic/AvisoGenerico"

class ErrorCheckout extends Component {
    render() {
        return (
        <AvisoGenerico>
            <Box width="1" height="100%"  textAlign="center" >
                <Box  style={{ paddingTop: '40%' }}  textAlign="center">
                    <img src={Info} alt="Info restaurante fechado" />
                </Box>
                <Typography variant="h5" color="primary"><strong>O restaurante ainda<br/> está fechado</strong></Typography>
                <Typography variant="h10" color="primary">Mas você poderá ver nosso cardápio.<br/> Só não poderá realizar pedidos até abrirmos.</Typography>

                <Box width={1} mt={2}> 
                 <Button variant="contained" color="primary" onClick={ () =>{  this.props.history.replace('/') } }>
                    VER CARDÁPIO
                 </Button>
                </Box>
            </Box>
        </AvisoGenerico>
        );
    }
}
export default ErrorCheckout;


