
const URL_BASE = 'https://appws.picpay.com/ecommerce/public'

export const STATUS_PICPAY = {
    paid: "PAGO", 
    created: "AGUARDANDO PAGAMENTO", 
    expired: "PRAZO PARA PAGAMENTO EXPIRADO",
    analysis: "PAGO E EM PROCESSO DE ANÁLISE ANTI-FRAUDE",
    completed: "PAGO E SALDO DISPONÍVEL",
    refunded: "PAGO E DEVOLVIDO",
    chargeback: "PAGO E COM CHARGEBACK",
}


export default class PicPay {

    constructor({picpayToken, sellerToken}) {
        this.X_PICPAY_TOKEN = picpayToken
        this.X_SELLER_TOKEN = sellerToken
    }


   /**
     * @param {Object} payment     Objeto de pagamento: 
     * Ex: 
      let payment = {
      "referenceId": id,
      "callbackUrl": "http://localhost:3000/callback",
      "returnUrl": "http://localhost:3000/cliente/pedido/102030",
      "value": valorCompra,
      "expiresAt": "2022-05-01T16:00:00-03:00",
      "buyer": {
        "firstName": nome,
        "lastName": sobrenome,
        "document": documento,
        "email": email,
        "phone": fone
      }
    }
     */ 
   doPayment = (payment) => {
        let myHeaders = new Headers();
        myHeaders.append("x-picpay-token", this.X_PICPAY_TOKEN)
        myHeaders.append("Content-Type", "application/json")

        let options = { 
            method: 'POST',
            headers: myHeaders,
            mode: 'cors', 
            body: JSON.stringify( payment ) 
        }

    return fetch(`${URL_BASE}/payments`, options)
    .then(response =>  {
            console.log("Retorno solicitação pagamento picpay", response)
            
            if (response.status === 200) { 
                return response.json()
            } else {
                var error = new Error(response.statusText)
                error.response = response
                throw error
            }
        })
   }    

    /**
     * Retorna o status de uma solicitação de pagamento
     */
    statusRequest = (referenceId) => {
        let myHeaders = new Headers();
        myHeaders.append("x-picpay-token", this.X_PICPAY_TOKEN)
        myHeaders.append("Content-Type", "application/json")

        let options = { 
            method: 'GET',
            headers: myHeaders,
            mode: 'cors' 
        };

        return fetch(`${URL_BASE}/payments/${referenceId}/status`, options)
        .then(response =>  {
                console.log("Retorno solicitação status picpay", response)
                
                if (response.status === 200) { 
                    return response.json()
                } else {
                    var error = new Error(response.statusText)
                    error.response = response
                    throw error
                }
        })  

    }    

    /**
     * Cancela um pagamento autorizado
     */
    cancelPayment = (referenceId, authorizationId) => {
        let myHeaders = new Headers();
        myHeaders.append("x-picpay-token", this.X_PICPAY_TOKEN)
        myHeaders.append("Content-Type", "application/json")

        let bodyContent = {
            authorizationId
        }

        let options = { 
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            body: JSON.stringify( bodyContent )  
        };

        return fetch(`${URL_BASE}/payments/${referenceId}/cancellations`, options)
            .then(res => res.json());
    }  


    /**
     * Obtem o orderId a partir da URL gerada pelo PicPay
     */
    getOrderIdByUrl = (url) => {
        var orderId = url.split('/').pop();
        return orderId;
    }



    /**
     * Retorna o qrcode em formato base64
     */
    loadQrcode = (orderId) => {
        let myHeaders = new Headers();
        myHeaders.append("x-picpay-token", this.X_PICPAY_TOKEN)
        myHeaders.append("Content-Type", "application/json")

        let options = { 
            method: 'GET',
            headers: myHeaders,
            mode: 'cors' 
        };

        let URL_REQUEST = `https://appws.picpay.com/ecommerce/checkout/qr-code?order=${orderId}&url`

        return fetch(URL_REQUEST, options) 
        .then(response =>  {
                console.log("Retorno requisição QRCODE - status", response.status)
                if (response.status === 200) { 
                    return response.json()
                } else {
                    var error = new Error(response.statusText)
                    error.response = response
                    throw error
                }
            })
        }
}
