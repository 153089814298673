import React from "react";
import StatusLoja from '../../Info/StatusLoja'
import { getPerfile, ultimoPedidoIsUnfinished } from '../../../utils/PedidoUtil'
import TempoPreparo from "../../generic/TempoPreparo"
import Utils, { convertWhatsAppNumber } from '../../../utils/Utils'
import AuthService from "../../../services/AuthService";

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import { Box, Grid, IconButton, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        backgroundColor: theme.palette.personal.paperBackground,
    },
}));

export default function HeaderCardapio() {

    const theme = useTheme()
    const classes = useStyles();
    const profile = getPerfile()
    const enderecoSimples = `${profile.empresa.endereco}, ${profile.empresa.numero} - ${profile.empresa.bairro}`
    let isDelivery = profile.tipo === "DELIVERY"
    let whatsNumber = convertWhatsAppNumber(profile.empresa.foneWhatsapp)
    const mobile = useMediaQuery(theme.breakpoints.up('sm'));//verifica se a tela é de um dispositivo mobile

    const [empresa, setEmpresa] = React.useState({})

    const redireciona = (local) => {
        let profile = getPerfile()
        if (local == "facebook") {
            window.open(profile.empresa.urlFacebook);
        } else if (local == "instagram") {
            window.open(profile.empresa.urlInstagram);
        } else if (local == "whats") {
            let whatsNumber = convertWhatsAppNumber(profile.empresa.foneWhatsapp)
            window.open(`https://wa.me/${whatsNumber}?text=Olá, estou utilizando seu cardápio pelo qrpedir`);
        }
    }

    React.useEffect(() => {
        let idEmpresa = AuthService.getProfile().empresa.id
        let service = new AuthService()

        service
            .get(`/empresa/${idEmpresa}`)
            .then(resp => {
                setEmpresa(resp)
                // console.log("resp", resp)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <Grid container className={classes.paperBackground} >
            <Grid item sm={11}>
                <Box display="flex"
                    p={2}
                >
                    <Box>

                        <Box display="flex" >
                            <Box>
                                <ScheduleIcon fontSize="small" color='action' />
                            </Box>
                            <Box pl={1} flexGrow={1} >
                                <StatusLoja />
                            </Box>
                        </Box>

                        <Box display="flex" >
                            <Box>
                                <RoomOutlinedIcon fontSize="small" color='action' />
                            </Box>
                            <Box pl={1} flexGrow={1} >
                                <Typography variant="body2" color="textSecondary">
                                    {enderecoSimples}
                                </Typography>
                            </Box>
                        </Box>

                        {empresa.tempoMinPreparo || empresa.tempoMaxPreparo != 0 ? (
                            <Box display="flex" >
                                <Box  >
                                    <AlarmOnIcon fontSize="small" color='action' />
                                </Box>
                                <Box pl={1} flexGrow={1} >
                                    <Typography variant="body2" color="textSecondary">
                                        <TempoPreparo />
                                    </Typography>
                                </Box>
                            </Box>
                        ) : null}

                        {empresa.tempoRetirada > 0 && empresa.formaRetirada.indexOf('L') >= 0 && (
                            <Box display="flex" >
                                <Box >
                                    <AccessAlarmIcon fontSize="small" color='action' />
                                </Box>
                                <Box pl={1} flexGrow={1} >
                                    <Typography variant="body2" color="textSecondary">
                                        Tempo para retirada no local {empresa.tempoRetirada}m
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        {empresa.valorMinimo > 0 ? (
                            <Box display="flex" >
                                <Box  >
                                    <MonetizationOnOutlinedIcon fontSize="small" color='action' />
                                </Box>
                                <Box pl={1} flexGrow={1} >
                                    <Typography variant="body2" color="textSecondary">
                                        {`Pedido mínimo para entrega ${Utils.formatNumber(empresa.valorMinimo)}`}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : null}

                        {/* comentado aguardando refatoramento da tela menu.js para poder utilizar dispatch e useSelector
                        ultimoPedidoIsUnfinished() && (
                            <Box display="flex">
                                <Box>
                                    <RestaurantIcon fontSize="small" color='action'/>
                                </Box>
                                <Box pl={1} flexGrow={1} >
                                    <Typography variant="body2" color="textSecondary">
                                        Acompanhe seu pedido aqui
                                    </Typography>
                                </Box>                                
                            </Box>
                        )*/}

                    </Box>
                </Box>
            </Grid>
            <Grid item xs >{/*Caso a empresa tenha os dados de facebook, instagram o whats virifica cada um separadamente e apresenta na tela */}
                <Box display="flex" flexDirection={mobile ? "column" : "row" } justifyContent="center">
                    {profile.empresa.urlInstagram ? <IconButton onClick={() => redireciona("instagram")}><InstagramIcon /></IconButton> : null}
                    {profile.empresa.urlFacebook ? <IconButton onClick={() => redireciona("facebook")} ><FacebookIcon /></IconButton> : null}
                    {isDelivery && whatsNumber ? <IconButton onClick={() => redireciona("whats")}><WhatsAppIcon /></IconButton> : null}
                </Box>
            </Grid>
        </Grid>
    )
};