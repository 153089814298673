import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import Errors from "../../imagens/Error.svg";
import Button from '@material-ui/core/Button';

import AvisoGenerico from "../generic/AvisoGenerico"
import { getPerfile } from "../../utils/PedidoUtil";

class ErrorCheckout extends Component {
    render() {
        let empresa = getPerfile().empresa;
        return (
            <AvisoGenerico>
                <Box width="1" height="100%" textAlign="center" >
                    <Box style={{ paddingTop: '40%' }} textAlign="center">
                        <img alt='errors' src={Errors} />
                    </Box>
                    <Typography variant="h5" color="primary"><strong>Hei amigão</strong></Typography>
                    <Typography variant="body1" color="primary">Não estamos mais recebendo pedidos. </Typography>
                    <Typography variant="body2" display="block" color="primary">Estamos inativos :( </Typography>
                    <Typography variant="caption" display="block" color="primary">Entre em contato conosco </Typography>
                    <Typography variant="caption" display="block" color="primary">{empresa.foneWhatsapp}</Typography>

                    <Box width={1} p={2}>
                        <Button variant="contained" color="primary" onClick={() => { this.props.history.replace('/') }}>
                            Tentar novamente
                     </Button>
                    </Box>

                </Box>

            </AvisoGenerico>
        );
    }
}
export default ErrorCheckout;


