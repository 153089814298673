import React from "react";
import { Typography, CardContent, Grid, Box } from "@material-ui/core";
import Chamado from "../../../imagens/chamadogarcon.svg";

function DetItemTop(props) {
    const styles = {
        nome: {
            fontFamily: "Bebas Neue",
            fontSize: "25px",
            fontWeight: "500"
        }
    }

    return (
        <>
            <Box display="flex"  justifyContent="center" pb={2}>
                <img  style={{maxHeight: "20vh" }} src={Chamado} alt="Chamar garçom"/>
            </Box>
            <CardContent >
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item style={{ textAlign: 'center', marginTop: '-7%' }} xs={12}>
                        <Typography style={{ textTransform: 'uppercase' }}>
                            <strong style={styles.nome}>Chamar garçom</strong>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    );
}

export default DetItemTop;



