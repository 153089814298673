import React, { Component, Fragment } from 'react'
import CardGenerico from '../generic/CardGenerico'
import { Box, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton, Button, DialogTitle, DialogContent, Dialog } from '@material-ui/core'
import Utils from '../../utils/Utils'
import moment from 'moment'
import { calcularValorTotalPedido, getPerfile, TIPO_DELIVERY } from '../../utils/PedidoUtil'
import CustomizedSteppers from './components/ProgressBar'
import AuthService from '../../services/AuthService'
import HomeIcon from '@material-ui/icons/Home';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import PlaceIcon from '@material-ui/icons/Place';
import LaunchIcon from '@material-ui/icons/Launch';
import EventIcon from '@material-ui/icons/Event';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CancelIcon from '@material-ui/icons/Cancel';
import StarIcon from '@material-ui/icons/Star';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TempoPreparo from '../generic/TempoPreparo'
import TodayIcon from '@material-ui/icons/Today';
import StorefrontIcon from '@material-ui/icons/Storefront';
import RestaurantIcon from '@material-ui/icons/Restaurant';

import { QR_Code, CodigoCopiaECola } from '../Pessoa/components/CopiaECola';
import { withSnackbar } from 'notistack'


export class InfoPedido extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pedido: null,
            activeStep: 0,
            openPedidoRecebido: false
        }
    }


    changeStep = () => {
        let { pedido } = this.state;

        // eslint-disable-next-line eqeqeq
        if (pedido.situacao == "PENDENTE") {
            this.setState({ activeStep: 0 })
            // eslint-disable-next-line eqeqeq
        } else if ("RECEBIDO" == pedido.situacao) {
            this.setState({ activeStep: 0 })
            // eslint-disable-next-line eqeqeq
        } else if ("CONFIRMADO" == pedido.situacao) {
            this.setState({ activeStep: 1 })
            // eslint-disable-next-line eqeqeq
        } else if ("ENVIADO" == pedido.situacao) {
            this.setState({ activeStep: 2 })
            // eslint-disable-next-line eqeqeq
        } else if ("ENTREGUE" == pedido.situacao) {
            this.setState({ activeStep: 3 })
        }
    }

    componentDidMount() {
        this.getPedido();
        window.addEventListener("focus", this.onPageFocus)
    }
    componentWillUnmount() {
        window.removeEventListener("focus", this.onPageFocus)
    }
    onPageFocus = () => {
        this.getPedido();
    }

    getPedido = () => {
        let service = new AuthService();

        service
            .get(`/pedido/uuid/${this.props.match.params.uuid}`)
            .then(resp => {
                this.setState({ pedido: resp }, () => {
                    this.changeStep()
                })
                this.handleOpenDialog()
            })
            .catch(err => {
                this.props.enqueueSnackbar("Falha ao buscar situação do pedido", { variant: "error" });
                console.error(err)
            })
    }

    handleClosePage = () => {
        this.props.history.replace("/");
    }

    formatDate = (date) => {
        return moment(new Date(date)).format("DD/MM HH:mm")
    }

    handleOpenDialog = () => {
        const { pedido } = this.state

        if (pedido?.situacao === "PENDENTE") {
            this.setState({ openPedidoRecebido: true })
        }
    }

    handleCloseDialog = () => {
        this.setState({ openPedidoRecebido: false })
    }

    render() {
        const { pedido, activeStep, openPedidoRecebido } = this.state;
        let empresa = getPerfile().empresa;
        let enderecoSimples = `${empresa.endereco}, ${empresa.numero}`
        let enderecoCompleto = `${enderecoSimples} - ${empresa.municipio.nome}, ${empresa.estado.sigla}`
        /*Ao listar o bairro verifica se a variável de lista de bairros possui o valor Bairro, se não mostra o que foi digitado pelo cliente */
        let bairroEntrega = pedido?.empresa?.configuracaoEntrega === 'BAIRRO' ? pedido?.pessoa?.bairroEntrega?.bairro : pedido?.pessoa?.bairro;

        return (
            <React.Fragment>
                <CardGenerico
                    rightButtonClick={this.handleClosePage}
                    cardContent={
                        pedido ? (

                            <Box justifyContent="center" pt={0} display="flex" flexDirection="column" style={{ overflow: "hidden" }}>
                                <Box mt={-1} display="flex" justifyContent="center" style={{ fontSize: "200%", height: "40px" }}>

                                    {pedido.identificador.tipo === "SEQUENCIAL" ?
                                        <div >
                                            <strong>Sua senha é {pedido.identificadorSecundario}</strong>
                                        </div>
                                        : null}

                                    {pedido.identificador.tipo === "DELIVERY" ?
                                        <div >
                                            <strong>Delivery</strong>
                                        </div>
                                        : null}

                                    {(pedido.identificador.tipo !== "DELIVERY" && pedido.identificador.tipo !== "SEQUENCIAL") ?
                                        <div >
                                            <strong>{pedido.identificador.tipo} {pedido.identificador.numero}</strong>
                                        </div>
                                        : null}

                                </Box>

                                <Typography align="center" variant="body2" color="textSecondary" component="p">
                                    #{pedido.id}
                                </Typography>

                                <Box>
                                    <CustomizedSteppers pedido={pedido} activeStep={activeStep} />

                                    <Box mb={2} display="flex" alignContent="center" justifyContent="center">
                                        {pedido.pagamentos.map(pagamento => (
                                            pagamento.formaPagamento.tipo === "PIX" ? (
                                                <CodigoCopiaECola pedido={pagamento.formaPagamento} total={calcularValorTotalPedido(pedido)}></CodigoCopiaECola>
                                            ) : null
                                        ))}
                                    </Box>

                                    <Box display="flex" alignContent="center" justifyContent="center">
                                        <Button onClick={() => this.props.history.push(`/avaliacaoEmpresa/${pedido.uuid}`)} variant="contained" color="secondary">
                                            <StarIcon /> <strong style={{ marginLeft: "10px" }}>Avalie o pedido</strong>
                                        </Button>
                                    </Box>

                                    <List dense>

                                        {
                                            pedido.situacao === "CANCELADO" ? (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CancelIcon color="error" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        secondaryTypographyProps={{ color: "error" }}
                                                        primary="Pedido cancelado"
                                                        secondary={this.formatDate(pedido.dataCancelado)} />
                                                </ListItem>
                                            ) : null
                                        }

                                        <ListItem>
                                            <ListItemIcon>
                                                <TodayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Pedido realizado" secondary={this.formatDate(pedido.dataPedido)} />
                                        </ListItem>

                                        {pedido.horarioAgendamento ? (
                                            <ListItem>
                                                <ListItemIcon>
                                                    <EventIcon color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary="Agendado para" secondary={this.formatDate(pedido.horarioAgendamento)} />
                                            </ListItem>
                                        ) : null}

                                        {pedido.identificador.tipo === "DELIVERY" ? (
                                            <Fragment>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        {pedido.tipoDelivery === "ENTREGAR" ? (
                                                            <ListItemIcon>
                                                                <MotorcycleIcon />
                                                            </ListItemIcon>
                                                        ) : pedido.tipoDelivery === "CONSUMIR_LOCAL" ? (
                                                            <ListItemIcon>
                                                                <RestaurantIcon />
                                                            </ListItemIcon>
                                                        ) : pedido.tipoDelivery === "RETIRAR_LOCAL" ? (
                                                            <ListItemIcon>
                                                                <StorefrontIcon />
                                                            </ListItemIcon>
                                                        ) : null}
                                                    </ListItemIcon>
                                                    <ListItemText primary={TIPO_DELIVERY[pedido.tipoDelivery]} />
                                                </ListItem>

                                                <ListItem >
                                                    <ListItemIcon>
                                                        <AccessTimeIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={pedido.tipoDelivery === "RETIRAR_LOCAL" && empresa.tempoRetirada !== 0 ?

                                                        (`Tempo para retirada - ${empresa.tempoRetirada}m`) :

                                                        pedido.tipoDelivery === "CONSUMIR_LOCAL" && empresa.tempoRetirada !== 0 ?

                                                            (`Tempo de preparo - ${empresa.tempoRetirada}m`) :

                                                            <TempoPreparo />} />
                                                </ListItem>

                                                {pedido.tipoDelivery === "ENTREGAR" ? (
                                                    <ListItem >
                                                        <ListItemIcon>
                                                            <HomeIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Endereço de entrega" secondary={`${pedido.pessoa.endereco}, ${pedido.pessoa.numero} - ${bairroEntrega}`} />
                                                    </ListItem>
                                                ) : null}

                                                {pedido.tipoDelivery === "RETIRAR_LOCAL" || pedido.tipoDelivery === "CONSUMIR_LOCAL" ? (
                                                    <ListItem >
                                                        <ListItemIcon>
                                                            <PlaceIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={pedido.tipoDelivery === "RETIRAR_LOCAL" ? "Endereço para retirada no local" : "Endereço para consumo"}
                                                            secondary={`${empresa.endereco}, ${empresa.numero} - ${empresa.bairro}`} />
                                                        <ListItemSecondaryAction>
                                                            <IconButton href={`https://www.google.com/maps/place/${enderecoCompleto}`} target="_blank"  ><LaunchIcon /></IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ) : null}

                                            </Fragment>
                                        ) : null}

                                    </List>

                                    <Grid container>
                                        <Typography align="center" style={{ width: "100%" }}>
                                            <strong>
                                                Itens
                                            </strong>
                                        </Typography>
                                        {pedido ?
                                            pedido.itens.map(item => (
                                                <Grid item xs={12} key={`item-${item.id}`} style={{ margin: "5px 0" }}>
                                                    <Box p={1} ml={2} >
                                                        <Typography variant="body1" color="textPrimary" component="p">
                                                            {item.quantidade} x {item.produto.nome}
                                                        </Typography>

                                                        {item.subItens ? item.subItens.map(subIten => (
                                                            <Typography key={`subIten-${subIten.id}`} variant="body2" color="textSecondary" component="p">
                                                                &nbsp; &nbsp; &nbsp; - {subIten.quantidade} x {subIten.descricao}
                                                            </Typography>
                                                        )) : null}

                                                        {item.observacao ? (
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                {item.observacao}
                                                            </Typography>
                                                        ) : null}
                                                    </Box>
                                                </Grid>
                                            ))
                                            : null}
                                    </Grid>

                                    <List dense >

                                        {pedido.pagamentos.map(pagamento => (
                                            <ListItem key={pagamento.id} >
                                                <ListItemIcon>
                                                    <AttachMoneyIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={`${pagamento.formaPagamento.descricao}`} />
                                                {pagamento.formaPagamento.tipo === "PIX" ? (
                                                    // eslint-disable-next-line react/jsx-pascal-case
                                                    <QR_Code pedido={pagamento.formaPagamento} total={calcularValorTotalPedido(pedido)} > </QR_Code>
                                                ) : null}
                                            </ListItem>
                                        ))}

                                    </List>

                                    <Box textAlign="center">
                                        <Typography variant="h6">
                                            {`TOTAL ${Utils.formatNumber(calcularValorTotalPedido(pedido))}`}

                                        </Typography>

                                    </Box>

                                </Box>
                            </Box>

                        ) : null
                    }
                >

                </CardGenerico>

                <Dialog open={openPedidoRecebido}>
                    <DialogTitle>Atenção</DialogTitle>
                    <DialogContent>
                        Seu pedido ainda não foi confirmado, porém já foi recebido.
                    </DialogContent>
                    <Box display="flex" justifyContent="center" style={{ marginBottom: '15px' }}>
                        <Button color="primary" onClick={this.handleCloseDialog} variant='contained'>
                            OK
                        </Button>
                    </Box>
                </Dialog>

            </React.Fragment>
        )
    }
}

export default withSnackbar(InfoPedido);
