import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
import { Avatar, Box, CardActionArea, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Image from 'material-ui-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        // color: theme.palette.getContrastText(orange[Math.floor(Math.random()*1000 / 100) * 100]),
        // backgroundColor: orange[Math.floor(Math.random()*1000 / 100) * 100],
      },
}));

export default function LojaCard({ loja }) {
    const classes = useStyles()
    const openStore = loja?.aberta

    const openlinkLoja = () => {
        window.open(`//${loja?.url}`, '_Self')
    }

    return (
        <Card
            variant='outlined'
            elevation={1}
            onClick={openlinkLoja}
            style={{ opacity: openStore ? '1' : '0.3' }}
        >
            <CardActionArea >
                <Image
                    errorIcon={
                        <Avatar
                            className={classes.avatar}
                            alt={loja?.empresa?.nome?.toUpperCase()}
                            src="/broken-image.jpg" />}
                    src={loja?.empresa?.urlLogo || 'error'}
                />

                <Box p={1} >
                    <Tooltip title={loja?.empresa?.nome}>
                        <Typography display='block' noWrap gutterBottom variant="caption" >
                            {loja?.empresa?.nome?.toUpperCase()}
                        </Typography>
                    </Tooltip>

                    <Grid container direction='column' alignContent='center' alignItems='center'>
                        <Rating
                            readOnly={true}
                            name="avaliacaoLoja"
                            value={loja?.avaliacao}
                            max={5}
                            precision={0.5}
                            icon={<StarIcon size='small' />}
                            size='small'
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
                        />
                    </Grid>
                </Box>
            </CardActionArea>
        </Card>
    );
}
