import { Box, Button, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/AuthService';
import Template from '../../generic/Template';
import LojaCard from './cardLoja';
import Typography from '@material-ui/core/Typography';
import MunicipioDasLojas from './municpioDaslojas/index';
import FundoQrpedir from './../../../imagens/fundo-qrpedir-min.png'
import logoQrpedir from './../../../imagens/logo-azul.svg'
import CssBaseline from '@material-ui/core/CssBaseline';
import Nuvem from "../../../imagens/Nuvem.png";

export default function LojasAtivas(props) {

    const [openeOtherCity, setOpeneOtherCity] = useState(false)
    const [location, setLocation] = useState(JSON.parse(localStorage.getItem('_location')))
    const [lojaList, setLojaList] = React.useState()
    const service = new AuthService()
    const [errorPosition, setErrorPosition] = React.useState()


    function displayLocation(latitude, longitude) {
        var request = new XMLHttpRequest();

        var method = 'GET';
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            latitude + ',' + longitude + '&sensor=true&pt-BR&key=' + process.env.REACT_APP_GOOGLE_API_KEY;
        var async = true;

        request.open(method, url, async);
        request.onreadystatechange = function () {
            if (request.readyState === 4 && request.status === 200) {
                const data = JSON.parse(request.responseText);
                const address = data?.results[0];
                const city = address?.address_components?.filter(e => e.types.includes("administrative_area_level_2"))[0];
                const state = address?.address_components?.filter(e => e.types.includes("administrative_area_level_1"))[0];

                setLocation({ city: city?.long_name, state: state?.short_name })
            }
        };
        request.send();
    };


    function onClickEstouEm() {
        if (!location) {
            getCurrentPosition()
        } else {
            getLojasByStateCity(location)
        }
    }


    function getCurrentPosition() {
        var successCallback = function (position) {
            var x = position.coords.latitude;
            var y = position.coords.longitude;
            displayLocation(x, y);
        };

        var errorCallback = function (error) {
            setErrorPosition({ code: 0, message: 'Autorizado' });

            switch (error.code) {
                case 1:
                    setErrorPosition({ code: 1, message: 'Permissão negada' });

                    break;
                case 2:
                    setErrorPosition({ code: 2, message: 'Localização não encontrada' });

                    break;
                case 3:
                    setErrorPosition({ code: 3, message: 'Tempo esgotado' });
                    break;
                default:
                    setErrorPosition({ code: 0, message: 'Autorizado' });
                    break;
            }
            // document.write(errorMessage);
        };

        var options = {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 10
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);

    }

    function getLojasByStateCity(location) {
        service.getNoToken(`/empresa/listLojas?siglaEstado=${location.state}&nomeMunicipio=${location.city}`)
            .then(retorno => {
                setLojaList(retorno.sort((a, b) => {
                    let sortBy = [{
                        prop: 'aberta',
                        direction: -1
                    }, {
                        prop: 'nomeEmpresa',
                        direction: -1
                    }];

                    let i = 0
                    let result = 0

                    while (i < sortBy.length && result === 0) {
                        result = sortBy[i].direction *
                            (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1
                                : (a[sortBy[i].prop].toString() >= b[sortBy[i].prop].toString() ? 1 : 0));
                        i++;
                    }

                    return result;
                }));
            })
            .catch(err => {

                console.error(err);
            });
    }

    useEffect(() => {
        if (!location) {
            getCurrentPosition()
        }
    }, [])

    useEffect(() => {
        if (location) {
            localStorage.setItem('_location', JSON.stringify(location));

            getLojasByStateCity(location);
        }
    }, [location])


    return (
        <Template documentTitle="Lojas">
            <Container
                component='main'
                maxWidth='md'
                style={{
                    background: `url(${FundoQrpedir}) `,
                    paddingLeft: 0,
                    paddingRight: 0,
                }}

            >
                <CssBaseline />
                <Box pt={2} pr={1} pl={1}>
                    <Grid container direction="column"
                        justify="center"
                        alignItems="center">
                        <img
                            alt='logo-qrpedir'
                            src={logoQrpedir}
                            style={{ maxWidth: '35%' }} />
                    </Grid>
                </Box>

                <Box pt={3} pr={2} pl={2}>
                    <Grid container spacing={2} direction='row' >
                        <Grid item xs={12} >
                            <Typography
                                display="block" align="center"
                                color="textSecondary" gutterBottom
                                variant="subtitle1" component="h6">
                                E aí! Vamos pedir um RANGO?
                            </Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <div>
                                {(errorPosition?.code === 0 || location) &&
                                    <Button fullWidth color={'primary'} variant='contained' onClick={onClickEstouEm}>
                                        {`Estou em ${location?.city || ''}`}
                                    </Button>
                                }

                                <Button
                                    fullWidth
                                    color={'primary'}
                                    variant='text'
                                    onClick={() => setOpeneOtherCity(true)}
                                >
                                    {!location ?
                                        'Diga-me onde moras e te direi onde comereis'
                                        :
                                        'Estou em outra cidade'}

                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <Box pt={3} pr={2} pl={2}>

                    {lojaList && lojaList.length ?
                        <React.Fragment>
                            <Grid spacing={2} container direction='column' alignItems='center'>
                                <Typography gutterBottom variant="subtitle2" component="h6" align='center'>
                                    Aqui só tem rango TOP! 🍕🍔🌭🍩🥐
                                    </Typography>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">

                                {lojaList.map((value, index) => (
                                    <Grid key={index} item xs={6} sm={4} md={2} lg={2} xl={2}>
                                        <LojaCard loja={value} />
                                    </Grid>
                                ))}
                            </Grid>

                        </React.Fragment>
                        :
                        <Grid spacing={2} container direction='column' alignItems='center'>
                            <Typography gutterBottom variant="subtitle2" component="h6" align='center'>
                                {location?.city ?
                                    `Nenhum restaurante encontrado em ${location?.city} 😞😞😞` :
                                    `Escolha uma cidade 😎👆`}
                            </Typography>
                        </Grid>
                    }
                </Box >




                <Box >
                    <img alt='nuvem' src={Nuvem} style={{ width: "100%", marginTop: '5%' }} />
                </Box>

            </Container>


            <MunicipioDasLojas open={openeOtherCity} setOpen={setOpeneOtherCity} setLocation={setLocation} />

        </Template >
    );
}


