import React, { Component } from 'react';
import { Grid, TextField, Typography, Box, MenuItem } from '@material-ui/core';
import AuthService from "../../../services/AuthService";
import Robo from "../../../imagens/RoboCozinheiro.svg";
import MaskedInput from 'react-text-mask';
import { getCart, saveCart } from '../../../utils/PedidoUtil';

export class Endereco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enderecoErrorMsg: "",
      bairroErrorMsg: "",
      municipiosPermitidos: [],
      carregando: false,
      bairrosEntrega: [],
      configuracaoEntrega: ""
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.handleListCidadesPermissao();
    // this.handleConfiguracaoEntrega()
  }

  handleChange(event) {
    if (event.target.name === "endereco") {
      if (!event.target.value) {
        this.setState({ enderecoErrorMsg: "é obrigatório" })
      } else if (event.target.value.length < 3) {
        this.setState({ enderecoErrorMsg: "é inválido" })
      } else {
        this.setState({ enderecoErrorMsg: "" })
      }
    }

    if (event.target.name === "bairro") {
      if (!event.target.value) {
        this.setState({ bairroErrorMsg: "é obrigatório" })
      } else {
        this.setState({ bairroErrorMsg: "" })
      }
    }

    this.props.handleChange(event);
  }

  handleListCidadesPermissao() {
    let service = new AuthService();

    service.get("/permissaoMunicipio/listOrCreate?tipo=APP").then(retorno => {
        this.setState({ municipiosPermitidos: retorno })
        /* SE PESSOA AINDA NAO TEM MUNICIPIO, INFORMA O PRIMEIRO QUE VIER */
        if (!this.props.pessoa.municipio) {
          if (retorno.length > 0) {
            this.props.setMunicipioPessoa(retorno[0].municipio)
          }
        }
        this.handleConfiguracaoEntrega()
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleChangeMunicipio = (event) => {
    this.props.handleChange(event);

    if (this.state.configuracaoEntrega === "BAIRRO") {
      this.handleListBairrosEntrega()
    }
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        placeholder="xxxxx-xxx"
      />
    );
  }

  handleConfiguracaoEntrega() {
    let service = new AuthService()
    let idEmpresa = AuthService.getProfile().empresa.id

    service.get("/empresa/getOnly/" + idEmpresa + "?atributos=configuracaoEntrega").then(resp => {
        let configuracaoEntrega = resp.configuracaoEntrega || ""
        this.setState({ configuracaoEntrega })

        if (configuracaoEntrega === 'BAIRRO') {
          this.handleListBairrosEntrega();
        }
        /** SALVA NO PEDIDO A CONFIGURACAO DE ENTREGA UTILIZADA */
        var cart = getCart()
        cart.pedido.configuracaoEntrega = configuracaoEntrega

        saveCart(cart)
      })
      .catch(err => {
        console.error(err)
      })
  }

  handleListBairrosEntrega() {
    let service = new AuthService()

    service.get('/bairroEntrega/list?ativo=true&max=500').then(resp => {
        let listaBairros = []

        resp.map(bairro => {
          if (bairro.municipio.id === this.props.pessoa.municipio.id) {
            listaBairros.push(bairro)
          }
        })

        this.setState({ bairrosEntrega: listaBairros })
        
        if (listaBairros.length > 0) {
          const foundBairro = listaBairros.find((el) => el.bairro === this.props.pessoa.bairroEntrega.bairro)
          this.props.setBairroEntregaPessoa(foundBairro)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  handleChangeBairro = (event, values) => {
    this.props.handleChange(event, values);
  }

  render() {
    const { enderecoErrorMsg, bairroErrorMsg, municipiosPermitidos, bairrosEntrega, configuracaoEntrega } = this.state;

    return (
      <React.Fragment>
      <Grid item style={{ textAlign: 'center' }} xs={12}>
          <Grid width="100%">
              <Box style={{ margin: "0 auto" }}>
                  <img src={Robo} style={{ maxHeight: "20vh" }} />
              </Box>
          </Grid>
      </Grid>

        <Grid
          spacing={2}
          container
          direction="row"
          justify="center"
          alignItems="center">

          <Grid item xs={12}>
            <Typography align="center">
              <strong>Em qual local devemos levar?</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="selecionarMunicipio"
              select
              label="Município"
              value={JSON.stringify(this.props.pessoa.municipio)}
              name="municipio"
              onChange={event => this.handleChangeMunicipio(event)}
              fullWidth
              variant="outlined"
            >
              {municipiosPermitidos.map((option) => (
                <MenuItem key={option.id} value={JSON.stringify(option.municipio)}>
                  {option.municipio.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Endereço"
              required
              placeholder="Ex. Rua Tijucas"
              name="endereco"
              fullWidth
              onChange={event => this.handleChange(event)}
              variant="outlined"
              error={!!enderecoErrorMsg}
              value={this.props.pessoa.endereco}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              label="Núm"
              name="numero"
              fullWidth
              required
              onChange={event => this.handleChange(event)}
              variant="outlined"
              value={this.props.pessoa.numero}
            />
          </Grid>

          <Grid item xs={6} >
            <TextField
              label="Cep"
              name="cep"
              variant="outlined"
              defaultValue={this.props.pessoa.cep}
              fullWidth
              onChange={event => this.handleChange(event)}
              InputProps={{
                inputComponent: this.TextMaskCustom,
                value: this.props.pessoa.cep
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {configuracaoEntrega === 'BAIRRO' ? (
              <TextField
                id="selecionarBairro"
                select
                required
                label="Bairro"
                value={this.props.pessoa.bairroEntrega ? this.props.pessoa.bairroEntrega.id : ''}
                name="bairroEntrega"
                onChange={event => this.handleChangeBairro(event, bairrosEntrega)}
                fullWidth
                variant="outlined"
              >
                {bairrosEntrega.map((bairro) => (
                  <MenuItem key={bairro.id} value={bairro.id}>
                    {bairro.bairro}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                label="Bairro"
                name="bairro"
                fullWidth
                required
                onChange={event => this.handleChange(event)}
                variant="outlined"
                error={!!bairroErrorMsg}
                value={this.props.pessoa.bairro}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Complemento"
              name="complemento"
              fullWidth
              placeholder="Ex: Ap 2 ou casa verde"
              onChange={event => this.handleChange(event)}
              variant="outlined"
              value={this.props.pessoa.complemento}
            />
          </Grid>
          
        </Grid>
      </React.Fragment>
    )
  }
}

export default Endereco