import React, { Component } from "react";
import { SwipeableDrawer, IconButton, Divider, Button, withStyles, Badge, Box } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withRouter } from 'react-router-dom';
import Pedidos from "../components/pedidos"
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getNumeroPedidosJaRealizados, getPerfile } from '../../../utils/PedidoUtil'
import TempoPreparo from "../../generic/TempoPreparo"
import { converterURLImagemLogo, convertWhatsAppNumber } from "../../../utils/Utils"
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HorarioAbertura from "../../Info/HorarioAbertura";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import AuthService from "../../../services/AuthService";
import Logo from "../../../imagens/LogoQrpedir.svg";



const styles = {
    paper: {
        background: "#FFFFFF",
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'rigth',
        justifyContent: 'flex-end',
    },
    avatar: {
        maxWidth: '100px',
        maxHeight: '100px',
        width: '25vw',
        height: '25vw'
    },
    logoLarge: {
        width: "100px",
        height: "100px",
    },
    menuItem: {
        paddingRight: "35px"
    }
    

}

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openMenu: false,
            openHorarios: false
        }
        this.handleOpenClosePedidos = this.handleOpenClosePedidos.bind(this)
        this.handleOpenChamado = this.handleOpenChamado.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.abrirCategorias = this.abrirCategorias.bind(this)
    }

    handleChangeDialog = () => {
        let { openHorarios } = this.state;
        this.setState({ openHorarios: !openHorarios })
    }

    handleOpenClosePedidos = () => {
        this.setState({ open: !this.state.open })
    }

    handleOpenChamado = () => {
        this.props.history.push("/chamarGarcom");
    }

    handleToggle = () => {
        this.props.toogleMenu()
    }

    abrirCategorias = () => {
        this.props.openCategorias()
        this.handleToggle()
    }

    redireciona = (local) => {
        let profile = getPerfile()
        if(local == "facebook"){
            window.open(profile.empresa.urlFacebook);
        }else if(local == "instagram"){
            window.open(profile.empresa.urlInstagram);
        }else if(local == "whats"){
            let whatsNumber = convertWhatsAppNumber(profile.empresa.foneWhatsapp)
            window.open(`https://wa.me/${whatsNumber}?text=Olá, estou utilizando seu cardápio pelo qrpedir`);
        }
    }

    render() {
        const { classes, openMenu } = this.props;
        const { open, openHorarios } = this.state;

        let profile = getPerfile()
        let chamarGarcom = profile ? profile.empresa.chamarGarcom : false;
        let isDelivery = profile.tipo === "DELIVERY"
        let whatsNumber = convertWhatsAppNumber(profile.empresa.foneWhatsapp)
        let enderecoSimples = `${profile.empresa.endereco}, ${profile.empresa.numero}`
        let enderecoCompleto = `${enderecoSimples} - ${profile.empresa.municipio.nome}, ${profile.empresa.estado.sigla}`
        let formatoLogo = profile?.empresa?.formatoLogo === "square" ? "square" : "circular";
       
        return (
            <SwipeableDrawer
                variant="temporary"
                anchor="left"
                open={openMenu}
                onOpen={this.handleToggle}
                onClose={this.handleToggle}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={this.handleToggle} style={{ marginRight: "5px" }}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>

                <Box pt={1} display="flex" justifyContent="center">
                    {profile.empresa.urlLogo ? (
                        <Avatar alt={`logo da empresa ${profile.empresa.nome}`} variant={formatoLogo} src={converterURLImagemLogo(profile.empresa.urlLogo)} style={styles.logoLarge} />
                    ) : (
                        <Avatar alt="Menu" src="/assets/images/robo-qrpedir-menu.png" className={classes.avatar} />
                    )
                    }
                </Box>

                <Box display="flex" justifyContent="center" letterSpacing={2}>
                    <Typography pt={{ xs: 4, sm: 6, md: 8 }}
                        color="primary"
                        style={styles.nomeEmpresa}
                        variant="h6">
                        {AuthService.getProfile().empresa.nome}
                    </Typography>
                 </Box>

                <Box style={{ margin: "0 auto" }}>
                    <Button onClick={this.handleChangeDialog} 
                          size="small"
                        startIcon={<AccessTimeIcon  />}>
                            Horários
                    </Button>
                </Box>

                {isDelivery ? (
                    <React.Fragment>
                        {profile.empresa.tempoMinPreparo || profile.empresa.tempoMaxPreparo != 0 ? (
                            <Box  p={1} display="flex" justifyContent="center" color="primary"> <TempoPreparo /></Box>
                        ) : null}
                    </React.Fragment>
                ) : null}

                <Box p={1} display="flex" justifyContent="center" >
                    <div style={{maxWidth: '60vw'}}>
                    <Button size="small" href={`https://www.google.com/maps/place/${enderecoCompleto}`} target="_blank">
                        <RoomOutlinedIcon color="secondary" fontSize="small" />
                        <Typography variant="body2" color="textSecondary">
                            {enderecoSimples}
                        </Typography>
                    </Button>
                    </div>
                </Box>

                <Divider />
                <List>

                    <ListItem button onClick={this.handleOpenClosePedidos} disabled={getNumeroPedidosJaRealizados() <= 0}>
                        <ListItemIcon>
                            <Badge color="secondary" badgeContent={getNumeroPedidosJaRealizados()} max={50} >
                                <ShoppingCartOutlinedIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography className={classes.menuItem} >
                                Pedidos realizados
                            </Typography>
                        } />
                    </ListItem>

                    <ListItem button onClick={this.abrirCategorias} >
                        <ListItemIcon><FilterListIcon /></ListItemIcon>
                        <ListItemText primary={
                            <Typography className={classes.menuItem} >
                                Categorias
                            </Typography>
                        } />
                    </ListItem>

                    {!isDelivery && chamarGarcom ? (

                        <ListItem button onClick={this.handleOpenChamado} >
                            <ListItemIcon><NotificationsActiveIcon /></ListItemIcon>
                            <ListItemText primary={
                                <Typography className={classes.menuItem} >
                                    Chamar o garçom
                                    </Typography>
                            } />
                        </ListItem>

                    ) : null}

                </List>

                    <Box display="flex" justifyContent="center" pl={3} pr={3} pb={1} style={{position: "fixed", bottom: "0", width: "260px"}}>
                        {profile.empresa.urlInstagram ? <IconButton onClick={() => this.redireciona("instagram")} style={{marginRight: "13px"}}><InstagramIcon /></IconButton> : null}
                        {profile.empresa.urlFacebook ? <IconButton onClick={() => this.redireciona("facebook")} style={{marginRight: "13px"}}><FacebookIcon /></IconButton> : null}
                        {isDelivery && whatsNumber ? <IconButton onClick={() => this.redireciona("whats")}><WhatsAppIcon /></IconButton> : null}
                    </Box>

                <Pedidos
                    open={open}
                    handleOpenClosePedidos={this.handleOpenClosePedidos}
                    handleCloseMenu={this.handleToggle}
                />

                <HorarioAbertura open={openHorarios} handleChangeDialog={this.handleChangeDialog} />
            </SwipeableDrawer>

        )
    }

}

export default withRouter(withStyles(styles)(Menu));