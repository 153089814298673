import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import Errors from "../../imagens/Error.svg";
import Button from '@material-ui/core/Button';
import AvisoGenerico from "../generic/AvisoGenerico"

class ErrorCheckout extends Component {
    render() {
        return (
            <AvisoGenerico>
                <Box width="1" height="100%"  textAlign="center" >
                    <Box  style={{ paddingTop: '40%' }}  textAlign="center">
                        <img src={Errors} alt="Imagem perguntanto se ainda está ativo"/>
                    </Box>
                    <Typography variant="h5" color="primary"><strong>Esqueci onde você está!</strong></Typography>
                    <Typography variant="h10" color="primary">Poderia me mostrar novamente<br/>o Qrcode de sua mesa ou comanda ?</Typography>

                    <Box width={1} pt={2}> 
                        <Button variant="contained" color="primary"  onClick={ () =>{  this.props.history.replace('/readQRcode') } }>
                        Abrir câmera
                     </Button>
                    </Box>
                </Box>  
            </AvisoGenerico>
        );
    }
}
export default ErrorCheckout;



