import React, { useCallback, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { OutlinedInput, InputAdornment, IconButton, Box, makeStyles} from '@material-ui/core'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        backgroundColor: theme.palette.personal.paperBackground,
        borderRadius: '50px',
        maxHeight: '30px',
    },
    searchInput: {
        borderRadius: '50px',
        maxHeight: '30px',
        ['&.MuiOutlinedInput-adornedEnd']: {
            paddingRight: '3px'
        },
        ['&.MuiOutlinedInput-adornedStart']: {
            paddingLeft: '3px'
        },
    },
}));

export default function BuscarProdutos() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [search, setSearch] = useState('')
    const openSearch = useSelector(state => state.searchProduct.open)

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClickSearch(event)
        } else if (event.key === 'Escape') {
            handleCloseSearch()
        }
    }

    const handleCloseSearch = () => {
        setSearch("")
        dispatch({ type: 'SEARCH_PRODUCT', searchProduct:{search: '', open: false}})
    }

    const openSearchClick = () => {
        dispatch({ type: 'SEARCH_PRODUCT', searchProduct:{search: search, open: true}})
    }

    const handleClickSearch = () => {
        dispatch({ type: 'SEARCH_PRODUCT', searchProduct:{search: search, open: true}})
    }

    const clickSearchDelay = useCallback(debounce((searchProduct) => {
            if(searchProduct === ''){
                dispatch({ type: 'SEARCH_PRODUCT', searchProduct:{search: searchProduct, open: false}})
            } else{
                dispatch({ type: 'SEARCH_PRODUCT', searchProduct:{search: searchProduct, open: true}})
            }
        }, 400),
        []
    );    
    
    useEffect(()=>{
        clickSearchDelay(search)
    },[search])

    return (
        <Box padding={1}>
            {!!openSearch ? (
                <Box className={classes.paperBackground}>
                    <OutlinedInput
                        placeholder="Buscar produtos"
                        fullWidth
                        autoFocus
                        value={search}
                        name="pesquisa"
                        variant="outlined"
                        onKeyUp={handleKeyPress}
                        onChange={(event) => setSearch(event.target.value)}
                        className={classes.searchInput}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton size="small" color="primary" variant="contained" onClick={(event) => handleClickSearch(event)} >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton size="small" color="primary" variant="contained" onClick={handleCloseSearch} >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
            ) : (
                <IconButton onClick={openSearchClick} color="inherit" size="small">
                    <SearchIcon />
                </IconButton>
            )}
        </Box>
    )
}