export default class AuthService {
    // Inicializando variáveis ​​importantes
    constructor(domain) {
        this.domain = domain ||  process.env.REACT_APP_URL_API // Dominio do servidor da API
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

   getEmpresaStatusLoja(onComplet) {
        let profile = AuthService.getProfile()
        let tipo = profile.tipo === "DELIVERY" ? "DELIVERY" : "COMANDA"
        this.get(`/empresa/statusLoja?tipo=${tipo}`)
        .then(res => {   
            localStorage.setItem('_statusLoja', res[0].statusAbertura) 
            onComplet(res[0])
        }).catch(err => {
            console.error('ERRO NA BUSCA DO STATUS DA LOJA', err)
        })
    }

    static _statusLoja(){
        return localStorage.getItem('_statusLoja') 
    }

    loggedIn() {
        // Verifica se há um token salvo e ainda é válido
        const token = this.getToken() // Obtendo token do localstorage
        return !!token;
    }
 
    setToken(idToken) {
        // Salva o token do usuário em localStorage
        localStorage.setItem('id_token', idToken) 
    }


    getNoToken(url) {
        return fetch(this.domain + url, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    getToken() {
        // Recupera o token do usuário do localStorage
        return localStorage.getItem('id_token')
    }

    static getTokenStatic(){
        return localStorage.getItem('id_token')
    }

    logout() {
        // Limpar token do usuário e dados de perfil do localStorage
        localStorage.removeItem('id_token');
        this.setProfile(null)
    }

    static getProfile() {
        let profileString = localStorage.getItem("_profile")
        return JSON.parse(profileString)
    }

    setProfile(profile) {
        localStorage.setItem("_profile", JSON.stringify(profile))
    }


    fetch(url, options) {
        const token = this.getToken()

        if (!options) {
            options = {}
        }
        options.headers = {
            "Contexto" : (token != null ? token : ''),
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*"
        }
        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }

    post(url, obj) {
        return fetch(this.domain + url, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                "Contexto" : this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    get(url, obj) {
        return fetch(this.domain + url, {
            method: 'GET',
            body: JSON.stringify(obj),
            headers: {
                "Contexto" : this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    put(url, obj) {
        return fetch(this.domain + url, {
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                "Contexto" : this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    delete(url, obj) {
        return fetch(this.domain + url, {
            method: 'DELETE',
            body: JSON.stringify(obj),
            headers: {
                "Contexto" : this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // gera um erro caso o status da resposta não seja um sucesso
        if (response.status >= 200 && response.status < 300) { // O status de sucesso situa-se entre 200 e 300
            return response
        } else {
            var error = { message: response.statusText, response }
            //error.response = response
            throw error
        }
    }
}