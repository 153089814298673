import { diffSecondDate, createUUID } from './Utils';

import {
    osName,
    osVersion,
    mobileVendor,
    mobileModel
} from "react-device-detect";
import AuthService from '../services/AuthService';


const CART_KEY = "_cart"
const ULTIMOS_PEDIDOS = "_ultimosPedidos"
const NOME_CLIENTE = "_nomeCliente"
const NUMERO_IDENTIFICADOR = "_numeroIdentificador"
const CONFIGURACOES = "_configuracoes"
const PROFILE = "_profile"
const DISPOSITIVO = "_DISPOSITIVO"
const SEQUENCIAL = "_sequencial"
const PESSOA_DELIVERY = "_pessoa_delivery"

export const recalcularTotalItem = (item) => {
    let total = (item.valorUnitario * item.quantidade) - (item.desconto || 0)
    item.valorTotal = total
    return item;
};

export const getPerfile = () => {
    return JSON.parse(localStorage.getItem(PROFILE));
}

export const calcularValorTotalItensPedido = (pedido) => {
    let total = 0
    pedido.itens.map(item => {
        total += calcularValorTotalIItem(item)
        // total += item.valorTotal
        // if (item.subItens) {
        //     item.subItens.map(item => {
        //         total += item.valorTotal
        //     })
        // }
    })

    return total
}

export const calcularValorTotalIItem = (item) => {
    let  total = item.valorTotal
    if (item.subItens) {
            item.subItens.map(item => {
                total += item.valorTotal
        })
    }

    return total
}

export const calcularValorTotalPedido = (pedido) => {
    let total = calcularValorTotalItensPedido(pedido)

    if (pedido.tipoDelivery === "ENTREGAR" && pedido.valorTaxaEntrega){
        total += pedido.valorTaxaEntrega
    }

    if (pedido.valorDesconto > 0) {
        total = (total - pedido.valorDesconto)
    }

    return total
}


export const calcularFracaoParaComplemento = (complemento) => {
    let { fracao } = calcularFracaoParaComplementoCompleto(complemento)
    return fracao;
}

export const calcularFracaoParaComplementoCompleto = (complemento) => {

    let fracao = 1.0;
    let qtdeTotalItens = 0;

    if (complemento.tipoComplemento !== "PIZZA_FRACIONADA" && complemento.tipoComplemento !== "PIZZA_MAIOR_VALOR" ){
        fracao = 1.0
    }

    if (!complemento.complementoItens){
        fracao = 1.0
    }

    complemento.complementoItens.map(complementoItem => {
        if (complementoItem.quantidade)
            qtdeTotalItens += complementoItem.quantidade
    })

    fracao = 1.0 / qtdeTotalItens;

    return {
        fracao,
        qtdeTotalItens
    };
}

export const calculaMaiorValorUnitario = (complemento) => {

    let maiorValorUnitario = 0.0;
    
    complemento.complementoItens.map(complementoItem => {
        if (complementoItem.quantidade){
            if (complementoItem.valor > maiorValorUnitario){
                maiorValorUnitario = complementoItem.valor
            }
        }
    })

    return maiorValorUnitario;
}

export const calcularValorTotalItemComComplemento = (item, complementos) => {
    let total = 0

    complementos.map(complemento => {
         
        if (complemento.tipoComplemento === "PIZZA_FRACIONADA" ){

            let fracaoPorQtde = calcularFracaoParaComplemento(complemento);

            complemento.complementoItens.map(complementoItem => {
                if (complementoItem.quantidade)
                    total += complementoItem.valor * ( complementoItem.quantidade * fracaoPorQtde); 
            })

          } else if (complemento.tipoComplemento === "PIZZA_MAIOR_VALOR"){

            let fracaoPorQtde = calcularFracaoParaComplemento(complemento);
            let maiorValor = calculaMaiorValorUnitario(complemento);
          
            complemento.complementoItens.map(complementoItem => {
                if (complementoItem.quantidade)
                    total += maiorValor * ( complementoItem.quantidade * fracaoPorQtde); 
            })
    
        } else {
            complemento.complementoItens.map(complementoItem => {
                if (complementoItem.quantidade)
                    total += complementoItem.valor * complementoItem.quantidade
            })
        }

    })
    total += item.valorTotal

    if (item.quantidadePrincipal){
        total = total * item.quantidadePrincipal
    }
    return total
}


export const saveCart = (cart) => {
    localStorage.setItem(CART_KEY, JSON.stringify(cart))
}

export const savePessoa = (pessoa) => {
    localStorage.setItem(PESSOA_DELIVERY, JSON.stringify(pessoa))
}

export const getCart = () => {
    let cartStr = localStorage.getItem(CART_KEY)
    let cart = null;

    if (cartStr) {
        cart = JSON.parse(cartStr)
    } else {
        cart = {
            pedido: {
                itens: []
            },
            createdAt: new Date()
        }
        saveCart(cart)
    }
    cart.temItens = function () {
        return this.pedido.itens.length > 0
    }
    cart.adicionarItemPedidoCorrente = adicionarItemPedidoCorrente;
    cart.valorTotalPedido = calcularValorTotalPedido(cart.pedido)
    cart.pedido.dispositivo = getHashDispositivo()
    //cart.excluirItemPedido = excluirItemPedido;
    cart.excluirItemPedidoPeloUUID = excluirItemPedidoPeloUUID;

    return cart
};

export const getPessoa = () => {
    let pessoaStr = localStorage.getItem(PESSOA_DELIVERY)
    let pessoa = null;

    if (pessoaStr) {
        pessoa = JSON.parse(pessoaStr)
    } 
    return pessoa;
}

export const adicionarItemPedidoCorrente = (item) => {
    let cart = getCart()
    let exists = false
    item.addAt = new Date().getTime()

    if (cart.pedido.itens.length) {

        cart.pedido.itens.forEach(pedido => {
            if (pedido.uuid === item.uuid) {
                let index = cart.pedido.itens.indexOf(pedido);
                cart.pedido.itens[index] = item;
                exists = true
            }
        })

        if(!exists) {
            item.uuid = createUUID()
            cart.pedido.itens.push(item)
        }

    } else {
        item.uuid = createUUID()
        cart.pedido.itens.push(item)
    }

    saveCart(cart)
}

// const excluirItemPedido = (pos) => {
//     console.log("DELETANTO POS " + pos)
//     let cart = getCart()
//     cart.pedido.itens.splice(pos,1);
//     saveCart(cart);
// } 

export const excluirItemPedidoPeloUUID = (uuid) => {
    console.log("DELETANTO UUID " + uuid)
    let cart = getCart()
    cart.pedido.itens = cart.pedido.itens.filter(elem => (elem.uuid !== uuid));
    saveCart(cart);
}


export const clearCart = () => {
    localStorage.removeItem(CART_KEY)
}

export const saveSequencial = (pedido) => {
    let sequencial = pedido.identificadorSecundario;
    localStorage.setItem(SEQUENCIAL, sequencial)
}

export const deleteSequencial = () => {
    localStorage.removeItem(SEQUENCIAL)
}

export const saveUltimoPedido = (pedido) => {
    let ultimosPedidos = []
    let ultimosPedidosStr = localStorage.getItem(ULTIMOS_PEDIDOS)
    if (ultimosPedidosStr) {
        ultimosPedidos = JSON.parse(ultimosPedidosStr)
    } 

    ultimosPedidos.push(pedido)

    if(ultimosPedidos.length >= 50) {
        ultimosPedidos = ultimosPedidos.slice(ultimosPedidos.length -50 , ultimosPedidos.length)
    }

    ultimosPedidosStr = JSON.stringify(ultimosPedidos)
    localStorage.setItem(ULTIMOS_PEDIDOS, ultimosPedidosStr)
}

export const getUltimoPedido = () => {
    let pedidosStr = localStorage.getItem(ULTIMOS_PEDIDOS)
    if (pedidosStr) {
        let ultimosPedidos = JSON.parse(pedidosStr)
        return ultimosPedidos[ultimosPedidos.length - 1]
    }
    return null;
};

export const getUltimosPedidos = (limit) => {
    let pedidosStr = localStorage.getItem(ULTIMOS_PEDIDOS)
    if (pedidosStr) {
        let ultimosPedidos = JSON.parse(pedidosStr)
        if (limit) {
            return ultimosPedidos.slice(0).slice(limit)  
        }
        return ultimosPedidos
    }
    return null;
};

export const getNumeroPedidosJaRealizados = () => {
    let pedidos = getUltimosPedidos()
    if (pedidos) {
        return pedidos.length
    }
    return 0;
};

/**
 * 
 * @param {*} pedido - a busca é realizada pelo id do pedido
 */
export const alterarPedidoNosUltimosPedidos = (pedido) => {
    let pedidos = getUltimosPedidos()
    if (pedidos) {
        var idx = pedidos.findIndex(pedidoNaLista => pedidoNaLista.id === pedido.id);
        if (idx < 0) {
            saveUltimoPedido(pedido)
        } else {
            pedidos.splice(idx, 1, pedido)
        }
    }else{
        saveUltimoPedido(pedido)
    }
};



export const saveNomeCliente = (nomeCliente) => {
    localStorage.setItem(NOME_CLIENTE, nomeCliente)
}

export const getNomeCliente = () => {
    return localStorage.getItem(NOME_CLIENTE)
}

export const saveNumeroIdentificador = (numeroIdentificador) => {
    localStorage.setItem(NUMERO_IDENTIFICADOR, numeroIdentificador)
}

export const getNumeroIdentificador = () => {
    return localStorage.getItem(NUMERO_IDENTIFICADOR)
}

export const saveConfiguracoes = (configuracoes) => {
    localStorage.setItem(CONFIGURACOES, JSON.stringify(configuracoes))
}
export const getConfiguracoes = () => {
    let configStr = localStorage.getItem(CONFIGURACOES)
    if (configStr) {
        return JSON.parse(configStr)
    }
    //configuracoes default
    return {
        ultimaLeituraQRCode: null
    }
}


export const isNecessarioRenovarQRCode = () => {

    if (getPerfile() === null){
        console.log("NENHUM PROFILE ENCONTRADO")
        return true;
    }

    if (getPerfile().tipo === "DELIVERY"){
        return false
    }

    let timeout = 30 * 60; //TEMPO DEFALT EM SEGUNDOS

    if (getPerfile().empresa) {
        let timeoutEmpresa = getPerfile().empresa.tempoValidadeQrcodeMinutos
        /* CONFIG ESTA EM MINUTOS, POR ISSO x 60 */
        timeout = (timeoutEmpresa ? (timeoutEmpresa * 60) : timeout)
    }

    let config = getConfiguracoes()
    if (config.ultimaLeituraQRCode) {
        let difSeconds = diffSecondDate(new Date(config.ultimaLeituraQRCode), new Date())
        console.log("Diferença da ultima leitura em segundos", difSeconds, "Timeout", timeout)
         
        if (difSeconds < timeout) {
            return false;
        }
    }

    //em todos os outros casos retorna true
    return true
}

export const limparDadosNovaSessao = () => {
    clearCart()
    new AuthService().logout()
    sessionStorage.clear()
}

export const getGrupoProdutosCache = () => {
    let produtosStr = sessionStorage.getItem('_produtos')
    if (produtosStr) {
        return JSON.parse(produtosStr)
    }
    return null
}

export const saveGrupoProdutosCache = (produtos) => {
    if (produtos) {
        sessionStorage.setItem('_produtos', JSON.stringify(produtos))
    } else {
        sessionStorage.removeItem('_produtos')
    }
}

export const getHashDispositivo = () => {

    let hashDispositivo = localStorage.getItem(DISPOSITIVO)
    if (!hashDispositivo) {
        /* GERAR */
        hashDispositivo = `OS${osName}_V${osVersion}_VEND${mobileVendor}_MODEL${mobileModel}_${new Date().getTime()}`
        localStorage.setItem(DISPOSITIVO, hashDispositivo)
    }

    return hashDispositivo;
}


export const utilizaIdentificadorSecundario = () => {
    if (getPerfile().empresa.tipoIdentificadorSecundario) {
        return getPerfile().empresa.tipoIdentificadorSecundario !== 'NAO_SE_APLICA';
    }
    return false;
}


export const duplicarItemCompleto = (item) => {
    let itemDuplicado = JSON.parse(JSON.stringify(item))

    //altera campos unicos
    itemDuplicado.uuid = null
    if(itemDuplicado.itens){
        itemDuplicado.map(subItem => {
            subItem.uuid = createUUID();
        })
    }

    return itemDuplicado;
}

export const SITUACAO_PEDIDO = {
    AGUARDANDO_CONFIRMACAO_PGTO: "AGUARDANDO PAGAMENTO",
    PENDENTE: "PENDENTE", 
    CONFIRMADO: "CONFIRMADO", 
    ENVIADO: "ENVIADO", 
    ENTREGUE: "ENTREGUE", 
    CANCELADO: "CANCELADO"
}

export const SITUACAO_PEDIDO_COR = {
    AGUARDANDO_CONFIRMACAO_PGTO: "#7e57c2",
    PENDENTE: "#ffc107", 
    CONFIRMADO: "#4caf50", 
    ENVIADO: "#795548", 
    ENTREGUE: "#bdbdbd", 
    CANCELADO: "#ef5350"
}

export const TIPO_DELIVERY = {
    ENTREGAR: "Para entregar",
    RETIRAR_LOCAL: "Para retirar",
    CONSUMIR_LOCAL:"Para consumir no local" 
}

export const getHavePedidoUnfinished = () =>{
    let pedidos = getUltimosPedidos()
    if (!!pedidos) {
        var idx = pedidos.findIndex(pedidoNaLista => pedidoNaLista.situacao !== "ENTREGUE" && pedidoNaLista.situacao !== "CANCELADO" && pedidoNaLista.tipoPedido === "DELIVERY");
        if(idx >= 0){
            return true
        }
    }
    return false
}

export const ultimoPedidoIsUnfinished = () => {
    let pedido = getUltimoPedido()
    if(!!pedido && pedido.situacao !== "ENTREGUE" && pedido.situacao !== "CANCELADO" && pedido.tipoPedido === "DELIVERY"){
        return true
    }

    return false
}

export const subscribeUltimosPedidos = (pedidosList) => {
    let ultimosPedidosStr = JSON.stringify(pedidosList)
    localStorage.setItem(ULTIMOS_PEDIDOS, ultimosPedidosStr)
}

export const getIdsPedidosStorage = () => {
    let pedidos = getUltimosPedidos()
    if (!!pedidos) {
        let idList = ""
        pedidos.map(pedido => {
            idList = idList + "," + pedido.id
        })

        idList = idList.slice(1)
        return idList
    }

    return null
}