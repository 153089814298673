import React, { Component } from "react";

import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, Container } from "@material-ui/core";

import Splash from "../../imagens/AnimacaoQrPedir.gif";
import AuthService from "../../services/AuthService";
import { getConfiguracoes, saveConfiguracoes, getCart} from '../../utils/PedidoUtil'
import { withSnackbar } from "notistack";
import {diffSecondDate} from '../../utils/Utils';
import {clearCart} from '../../utils/PedidoUtil';
import { ThemeContext, createTheme } from '../context/MyThemeContext'

const TOKEN = "id_token";
const PROFILE = "_profile";



class SplashScrean extends Component {
    
    static contextType = ThemeContext
    constructor(props) {
        super(props);
        const param = this.props.match.params;
        this.state = {
            hash: param.hash
        }

        this.aplicarTemaEmpresa = this.aplicarTemaEmpresa.bind(this)
        this.checkin = this.checkin.bind(this)
    }

    componentDidMount() {
        this.checkin()
    }

    aplicarTemaEmpresa(empresa){
        const themeContext = this.context
        let temaStr = empresa.tema
        if (temaStr){
            console.log('Alterado tema')
            let themeOptions = JSON.parse(temaStr)
            let theme = createTheme(themeOptions)
            themeContext.setMyTheme(theme)
        }
    }

    checkin(event) {

        const { hash } = this.state;

        const OLD_TOKEN = localStorage.getItem(TOKEN)

        localStorage.removeItem(TOKEN);
        localStorage.removeItem(PROFILE);
        localStorage.removeItem("_sequencial");
        localStorage.removeItem("_numeroIdentificador");

        if (hash !== OLD_TOKEN){
            console.log("Limpar carrinho. Hash ", hash, " TOKEN ", TOKEN)
            clearCart()
        }else{
            let createdAt = getCart().createdAt
           
            if (createdAt){
                /* SE DATA CARRINHO MAIOR QUE 120 MINUTOS, ZERA ELE */
                let today = new Date()
                var diffMs = (createdAt - today); 
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                if (diffMins > 120){
                    console.log("Limpar carrinho. Tempo maior que 120min")
                    clearCart()
                }else{
                    console.log("Mantem carrinho. Tempo menor que 120min. Tempo:", diffMins)
                }

            }
            console.log("Mantendo carrinho (mesmo hash)")
        }


        let service = new AuthService();
        service
            .get(`/identificador/load/${hash}`)
            .then(retorno => {
                localStorage.setItem("_profile", JSON.stringify(retorno))
                localStorage.setItem('id_token', retorno.uuid) 

                let empresa = retorno.empresa
                this.aplicarTemaEmpresa(empresa)

                let conf = getConfiguracoes()
                conf.ultimaLeituraQRCode = new Date()
                saveConfiguracoes(conf)

                 // validar se empresa esta ativa
                 if(!empresa.ativo){
                     this.props.history.replace("/errorSistemaBloqueado")
                     return 
                 }

                let dt1 = new Date(retorno.empresa.ultimoPing);
                let dt2 = new Date()

                let difInSec = diffSecondDate(dt2 , dt1)
              
                if(empresa.ultimoPing === null){
                    this.props.history.replace("/infoRestaurante");
                }else{
                    if (difInSec > 1800){
                       // this.props.history.replace("/infoRestaurante");
                        this.props.history.replace("/");
                        //this.props.history.replace("/infoRestaurante");
                    }else {
                        this.props.history.replace("/");
                    }
                }

            })
            .catch(err => {

                if (err.response === undefined) {
                    
                    this.props.history.replace("/errors");

                  } else {
                    err.response.json().then(elem => {
                      console.log(elem);
                      this.props.enqueueSnackbar(elem.errorMessage, { variant: "alert" });
                      this.props.history.replace("/errorsLojaNaoEncontrada");
                    });
                  }
            })
    }
                     
    render() {
        return (
            <React.Fragment>
            <Container component="main" maxWidth="sm" >

                    <CssBaseline />
                    <Box display="flex" justifyContent="center" alignItems="center" style={{  height: "90vh"}} >
                        <div style={{width: "250px"}}>
                            <img alt="qrPedir" src={Splash} style={{width: "100%" }}  />
                        </div>
                    </Box>

                </Container>
            </React.Fragment>
        );
    }
}

export default withSnackbar(SplashScrean);



