import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import SplashScreen from "./pages/SplashScreen";
import Products from "./pages/Products";
import MainPage from "./pages/Main";
import ConfirmarPedido from "./pages/ConfirmarPedido";
import Steps from "./pages/Steps";
import ConfirmaMesa from "./pages/ConfirmarPedido/components/compConfirmaPedido";
import ConfirmarPagamento from "./pages/ConfirmarPedido/components/confirmarPagamento";

import Errors from "./pages/Errors";
import ErrorsQrCode from "./pages/Errors/errorQrCode";
import ErrorsCamera from "./pages/Errors/errorAberturaDeCamera";
import ErrorSistemaBloequeado from "./pages/Errors/errorSistemaBloqueado";

import DadoUser from "./pages/ConfirmarPedido/components/compDadosUserName";
import DadoIdentificador from "./pages/ConfirmarPedido/components/compDadosIdentificador";

import ChamadoGarcon from "./pages/Chamados";
import InfoRestauranteFechado from "./pages/Info";
import ConfirmacaoMesa from "./pages/Chamados/components/compConfirmaMesa";
import ConfirmaChamado from "./pages/Chamados/components/compConfirmaChamado";
import MostraSequencial from "./pages/ConfirmarPedido/components/MostraSequencial";
import FinalizacaoDelivery from "./pages/ConfirmarPedido/components/FinalizacaoDelivery";
import AuthService from "./services/AuthService"

import Pessoa from "./pages/Pessoa";
import InfoPedido from "./pages/infoPedido";
import avaliacaoEmpresa from "./pages/avaliacaoEmpresa";
import ErrorLojaNaoEncontrada from "./pages/Errors/errorLojaNaoEncontrada";
import LojasAtivas from "./pages/empresas/lojas/index";

const SUBDOMINIOS_INVALIDOS = [
  "app",
  "station",
  "api",
  "qrpedir",
  "wwww",
  "lojas",
]

const HOST_CONHECIDOS = [
  "qrpedir.com",
  "localhost",
]

const getSubdominioDelivery = () => {
  const host = window.location.host;

  const hostConhecido = HOST_CONHECIDOS.find(elem => host.indexOf(elem) >= 0 )

  /* SE ENTRAR AQUI É OUTRO HOST, AI TENTA ENCONTRAR A LOJA */
  if (!hostConhecido){
    return host;
  }


  /* SE ENTRAR AQUI, É UM LINK PADRAO TIPO SUBDOMINIO DO QRPEDIR. EX: boteco-parseint.qrpedir.com */
  var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
  console.log("Subdominio", subdomain)
  if (subdomain){
      let encontrouSubdominioInvalido = SUBDOMINIOS_INVALIDOS.find(elem => elem === subdomain)

      if (encontrouSubdominioInvalido){
          console.log("Subdominio inválido", subdomain) 
          return null;
      }
      console.log("Subdominio válido:", subdomain) 
      let token = localStorage.getItem("id_token")
      if (subdomain !== token){
          localStorage.removeItem("id_token")
      }
      return subdomain;
  }


  return null;
}

const hasProfile = () => {
    const token = AuthService.getTokenStatic()
    
    if (!token){
      return false
    }

    const profile = AuthService.getProfile()
    return profile ? true : false 
  };


const ContextRoute = ({ component: Component, ...rest}) => (
  <Route {...rest} render={props => (
    hasProfile() ? (
      <Component {...props} />
    ) : (
      getSubdominioDelivery() ? (
        <Redirect to={`/checkout/${getSubdominioDelivery()}`} />
      ) : (
        <Redirect to='/readQRcode' />
      ) 
    )
  )} />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
        <ContextRoute exact path='/' component={Products} />
        <Route exact path='/readQRcode' component={MainPage} />
        <Route path='/checkout/:hash' component={SplashScreen} />
        <ContextRoute path='/products' component={Products} />
        <ContextRoute path='/dadosMesa' component={ConfirmacaoMesa} />
        <ContextRoute path='/pedido' component={ConfirmarPedido} />
        <ContextRoute path='/closePedido' component={ConfirmaMesa}/>
        <ContextRoute path='/confirmarPagamento/:uuid' component={ConfirmarPagamento}/>
        <ContextRoute path='/steps/:produtoId' component={Steps}/>
        <ContextRoute path='/dadoUser' component={DadoUser}/>
        <ContextRoute path='/dadoIdentificador' component={DadoIdentificador}/>
        <ContextRoute path='/closeChamado' component={ConfirmaChamado}/>
        <ContextRoute path='/Pessoa' component={Pessoa}/>
        <ContextRoute path='/Senha' component={MostraSequencial}/>
        <ContextRoute path='/chamarGarcom' component={ChamadoGarcon}/>
        <ContextRoute path='/infoRestaurante' component={InfoRestauranteFechado}/>
        <Route path='/errors' component={Errors} />
        <Route path='/errorsQrCode' component={ErrorsQrCode} />
        <Route path='/errorsCamera' component={ErrorsCamera} />
        <Route path='/errorsLojaNaoEncontrada' component={ErrorLojaNaoEncontrada} />
        <ContextRoute path='/errorSistemaBloqueado' component={ErrorSistemaBloequeado} />
        <ContextRoute path='/finalizacaoDelivery' component={FinalizacaoDelivery} />
        <ContextRoute path='/infoPedido/:uuid' component={InfoPedido} />
        <ContextRoute path='/avaliacaoEmpresa/:uuid' component={avaliacaoEmpresa} />
        <Route exact path="/lojas" component={LojasAtivas} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
