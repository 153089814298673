import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import Errors from "../../imagens/Error.svg";
import Button from '@material-ui/core/Button';
import AvisoGenerico from "../generic/AvisoGenerico"

class ErrorCheckout extends Component {
    render() {
        return (
            <AvisoGenerico>            
                <Box width="1" height="100%"  textAlign="center" >
                    <Box  style={{ paddingTop: '40%' }}  textAlign="center">
                        <img src={Errors} alt="Imagem de erro no app"/>
                    </Box>
                    <Typography variant="h5" color="primary"><strong>Todo mundo erra!</strong></Typography>
                    <Typography variant="body1" color="primary">dessa vez foram nossos servidores.</Typography>

                    <Box width={1} pt={3}> 
                        <Button variant="contained" color="primary" onClick={ () =>{  this.props.history.replace("/") } }>
                        Tentar novamente
                     </Button>
                    </Box>

                </Box>
            </AvisoGenerico>
        );
    }
}
export default ErrorCheckout;


