const { Merchant } = require('steplix-emv-qrcps');
const { Constants } = Merchant;

/*
API para gerar base 64 do valor qr code de chave pix copia e cola

npm install steplix-emv-qrcps --> instala o pacote steplix para utilizar o Merchant 
  que gera o código base64 que gera o QR Code

*/

// formata o texto e remove caracteres indevidos
const format_text = (text) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const formated_name = (nome) => {
  return format_text(nome);
}

const formated_city = (cidade) => {
  return format_text(cidade);
}

//remove caracteres do valor de pagamento para deixar padronizado o valor
const formated_amount = (amount) => {
  return amount.replace(',', '.').replace(' ', '').replace("R$", '');
}

//identa o valor da chave deacordo com o tipo da chave
// pois em cada modelo de chave o código é gerado de uma maneira diferente
const formated_key = (chave, tipoChave) => {
  var rkey = chave;
  var ktype = tipoChave.toLowerCase();

  if (ktype == 'telefone' || ktype == 'cnpj' || ktype == "cpf") {
    rkey = rkey.replace(/\D/g, '');
  }

  if (ktype == "telefone") {
    rkey = "+55" + rkey
  }

  return rkey
}

//recebe externamente as variáveis para calcular o código base64 
//  e o devolve para a criação do código QR
export const generate_qrcp = (chavePix, tipoChave, valor, nomeBeneficiario, cidadeBeneficiario) => {
  var name = nomeBeneficiario;
  var city = cidadeBeneficiario;
  var amount = valor;

  var emvqr = Merchant.buildEMVQR();
  // inicio valores padrões para pix no Brasil
  emvqr.setPayloadFormatIndicator("01");
  emvqr.setCountryCode("BR")
  emvqr.setMerchantCategoryCode("0000");
  emvqr.setTransactionCurrency("986"); // código da moeda
  const merchantAccountInformation = Merchant.buildMerchantAccountInformation();
  merchantAccountInformation.setGloballyUniqueIdentifier("BR.GOV.BCB.PIX");
  // fianl valores padrões para pix no Brasil
  merchantAccountInformation.addPaymentNetworkSpecific("01", formated_key(chavePix, tipoChave));

  emvqr.addMerchantAccountInformation("26", merchantAccountInformation);

  if (name) {
    emvqr.setMerchantName(formated_name(name));
  }

  if (city) {
    emvqr.setMerchantCity(formated_city(city));
  }

  if (amount && amount != '') {
    emvqr.setTransactionAmount(formated_amount(amount));
  }

  const additionalDataFieldTemplate = Merchant.buildAdditionalDataFieldTemplate();

  //Aqui é onde pode ser inserido o txId para confirmação de pagamento, no momento é ignorado
  additionalDataFieldTemplate.setReferenceLabel("***");

  /* caso for usar txId utilizar essa verificação
    if (this.reference) {
          additionalDataFieldTemplate.setReferenceLabel(this.formated_referance());
        }
        else {
          additionalDataFieldTemplate.setReferenceLabel("***");
        }

  */

  emvqr.setAdditionalDataFieldTemplate(additionalDataFieldTemplate);
  return emvqr.generatePayload();
}