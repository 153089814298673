import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Avatar, withTheme, Grid } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import Logo from "../../imagens/LogoQrpedir.svg";
import Nuvem from "../../imagens/Nuvem.png";
import AuthService from "../../services/AuthService";
import Cart from "../Cart";
import { getPerfile } from '../../utils/PedidoUtil';
import Box from '@material-ui/core/Box';
import { diffSecondDate, converterURLImagemLogo } from '../../utils/Utils';
import * as pedidoUtil from '../../utils/PedidoUtil';
import * as Pixel from '../../utils/FacebookPixelUtil';
import Typography from '@material-ui/core/Typography';
import MenuGrupos from './MenuGrupos';
import PainelPromocao from "./components/PainelPromocao";
import HeaderCardapio from './components/headerCardapio'
import MensagemDelivery from "./components/MensagemDelivery";
import CardapioList from "./components/CardapioList"
import { Provider } from 'react-redux'
import store from "../../store"
import GrupoList from "./components/GrupoList";
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle'
import BuscarProdutos from "./components/BuscarProduto";


class Products extends Component {


    constructor(props) {
        super(props);
        this.state = {
            grupos: [],
            buscandoProdutos: false,
            statusLoja: "",
            activeGroup: null
        }

        this.carregarCardapio = this.carregarCardapio.bind(this)
        this.handleClickOnCart = this.handleClickOnCart.bind(this)
        this.handleOpenChamado = this.handleOpenChamado.bind(this)
        this.posicionaListaGrupo = this.posicionaListaGrupo.bind(this)
        this.onHandlerScroll = this.onHandlerScroll.bind(this)
        this.onHandlerScrollDelay = this.onHandlerScrollDelay.bind(this)
        this.spySelectedGroupOnScroll = this.spySelectedGroupOnScroll.bind(this)
        this.getStatusLoja = this.getStatusLoja.bind(this)
    }




    componentDidMount() {

        if (Pixel.isPixelOn()) {
            Pixel.init();
            Pixel.trackPageView();
        }
        //this.handleStatusPing()
        if (pedidoUtil.isNecessarioRenovarQRCode()) {
            pedidoUtil.limparDadosNovaSessao()
            console.log("Redirecionando para a leitura do QR")
            this.props.history.replace("/readQRcode");
            return;
        } else {
            this.carregarCardapio()
        }

        //window.onscroll = () => this.onHandlerScrollDelay()
        window.addEventListener('scroll', throttle(this.onHandlerScroll, 100, { trailing: true, leading: true }));
        window.addEventListener('scroll', throttle(this.spySelectedGroupOnScroll, 1000, { trailing: true, leading: true }));


        this.getStatusLoja();

        window.onbeforeunload = function () {
            return 'Deseja sair do app?';
        }
        console.log('adiconando envento verifica status')
        window.addEventListener("focus", this.getStatusLoja)
    }

    componentWillUnmount() {
        window.onscroll = null;
        window.onbeforeunload = null;

        console.log('removendo  status')
        window.removeEventListener("focus", this.getStatusLoja)
    }

    getStatusLoja() {
        console.log('verificando status loja')
        let authService = new AuthService()
        let statusLoja = AuthService._statusLoja()

        this.setState({ statusLoja: statusLoja })

        authService.getEmpresaStatusLoja(resp => {
            this.setState({ statusLoja: resp.statusAbertura })
        })
    }

    posicionaListaGrupo(idGrupo) {
        let element = document.getElementById(`section_grupo_produto_${idGrupo}`)

        if (element) {
            const yOffset = -65;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y });

        } else {
            console.log("Elemento não encontrado para posicionar")
        }

    }

    handleStatusPing() {
        let dt1 = new Date(getPerfile().empresa.ultimoPing);
        let dt2 = new Date()

        let difInSec = diffSecondDate(dt2, dt1)
        if (getPerfile().empresa.ultimoPing === null) {
            //return false;
            return true;
        } else {
            if (difInSec > 1800) {
                //return false;
                return true;
            } else {
                return true;
            }
        }
    }

    carregarCardapio() {
        this.setState({ buscandoProdutos: true })
        let service = new AuthService();

        service
            .get("/grupoProduto/listComProdutos")
            .then(retorno => {
                this.setState({ grupos: retorno });
                this.setState({ buscandoProdutos: false })
            })
            .catch(err => {
                this.setState({ buscandoProdutos: false })
                this.props.history.replace("/errors");
            });
    }

    handleClickOnCart() {
        this.props.history.push("/pedido");
    }

    handleOpenChamado() {
        this.props.history.push("/chamarGarcom");
    }

    statusLojaFechada() {
        let status = AuthService._statusLoja() === "FECHADO"
        return status
    }

    onHandlerScrollDelay() {
        debounce(() => {
            this.onHandlerScroll()
        }, 200)
    }

    onHandlerScroll() {

        let { menuGrupoVisivel } = this.state

        const scrollTop = document.documentElement.scrollTop

        if (scrollTop > 100) {
            if (!menuGrupoVisivel) {
                this.setState({
                    menuGrupoVisivel: true
                })
            }
        } else {
            if (menuGrupoVisivel) {
                this.setState({
                    menuGrupoVisivel: false
                })
            }
        }
    }

    spySelectedGroupOnScroll() {

        let { activeGroup } = this.state

        const scrollTop = document.documentElement.scrollTop

        let groupElementList = document.getElementsByClassName("group-section")
        for (var i = 0; i < groupElementList.length; i++) {
            let element = groupElementList[i];
            if (scrollTop < element.offsetTop) {
                if (activeGroup !== i) {
                    this.setState({ activeGroup: i });
                }
                break;
            }
        }

    }

    render() {
        const styles = {
            nomeEmpresa: {
                fontFamily: "Bebas Neue",
                padding: '10px',
            },

            logoLarge: {
                width: "100px",
                height: "100px",
            },
            containerCardapio: {
                paddingLeft: "0",
                paddingRight: "0",
                backgroundColor: '#f4f4f4'
            }
        }

        let profile = getPerfile()
        const { grupos, buscandoProdutos, menuGrupoVisivel, activeGroup } = this.state;
        let isDelivery = profile && profile.tipo === "DELIVERY"

        let formatoLogo = profile?.empresa?.formatoLogo === "square" ? "square" : "circular";
        let empresaIsAtiva = profile?.empresa?.ativo ? true : profile?.empresa?.ativo; //Verifica se a empresa possuí status como ativa

        if (!empresaIsAtiva) {//se a empresa não estiver ativa redireciona para tela de sistema bloqueado
            this.props.history.replace("/errorSistemaBloqueado");
        }

        return (
            <Provider store={store}>
                <Container component="main" maxWidth="md"
                    style={styles.containerCardapio}
                    onScroll={this.onHandlerScroll}>
                    <CssBaseline />

                    <Box pt={7} pr={2} pl={2} pb={1}>
                        <Grid container>
                            <Grid item xs={12}>                                
                                <Box pt={2} display="flex" justifyContent="center">
                                    {profile.empresa.urlLogo ? (
                                        <Avatar alt={`logo da empresa ${profile.empresa.nome}`} variant={formatoLogo} src={converterURLImagemLogo(profile.empresa.urlLogo)} style={styles.logoLarge} />
                                    ) : (
                                        <img alt='logo' src={Logo} style={{ 'width': "45%", 'height': '60%' }} />
                                    )
                                    }
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" letterSpacing={2}>
                                    <Typography pt={{ xs: 4, sm: 6, md: 8 }}
                                        color="primary"
                                        style={styles.nomeEmpresa}
                                        variant="h5" component="h1">
                                        {AuthService.getProfile().empresa.nome}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>

                    {isDelivery ? (
                        <Box p={2}>
                            <HeaderCardapio />
                        </Box>
                    ) : null}

                    <MensagemDelivery />

                    <Box justifyContent="center" p={2}>
                        <GrupoList
                            buscandoProdutos={buscandoProdutos}
                            fixed={menuGrupoVisivel}
                            grupos={grupos}
                            onClick={this.posicionaListaGrupo}
                            activeIndex={activeGroup} />
                    </Box>

                    <Box justifyContent="center" pt={2} pr={2} pl={2}>
                        <PainelPromocao />
                    </Box>


                    <MenuGrupos grupos={grupos}
                        handleGrupoClick={this.posicionaListaGrupo}
                        showBtn={menuGrupoVisivel} />

                    <Box>

                        <CardapioList grupos={grupos} buscandoProdutos={buscandoProdutos} />

                    </Box>

                    <Cart handleClickOnCart={this.handleClickOnCart} />


                    <Box>
                        <img alt='nuvem' src={Nuvem} style={{ width: "100%", marginBottom: '-15%' }} />
                    </Box>
                </Container>

            </Provider>

        );
    }
}



export default withTheme(withRouter(Products));
