import React, { Component, Fragment } from "react";
import Robo from "../../../imagens/RoboCozinheiro.svg"
import AuthService from "../../../services/AuthService";
import {
    getCart,
    saveCart,
    saveUltimoPedido,
    clearCart,
    saveNomeCliente,
    getNomeCliente,
    utilizaIdentificadorSecundario,
    saveSequencial
} from '../../../utils/PedidoUtil';
import { Box, Typography, Grid, Fade, Button } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CardGenerico from '../../generic/CardGenerico'
import CustomButton from '../../generic/CustomButton'
import * as Pixel from '../../../utils/FacebookPixelUtil';
import MaskedInput from "react-text-mask";
import { onlyDigits } from "../../../utils/Utils";
import { withSnackbar } from "notistack";

class FormNome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: {
                nome: getNomeCliente() || '',
                telefone: localStorage.getItem("_telefoneCliente") || ''
            },
            enviandoPedido: false
        }

    }

    componentDidMount() {
        let profile = AuthService.getProfile()
        if (profile.tipo === "DELIVERY") {
            let { cliente } = this.state;

            this.props.history.replace({
                pathname: `/pessoa`,
                search: '',
                state: { nome: cliente.nome }
            })
        }
    }

    handleChange(event) {
        let { cliente } = this.state;
        cliente[event.target.name] = (event.target.value);
        this.setState({ cliente });
    }

    retornarParaListagem() {
        this.props.history.replace("/");
    }

    actionMsgClick = (snackMsg) => (
        <Fragment>
            <Button onClick={() => { 
                this.props.closeSnackbar(snackMsg);
                this.props.history.replace("/pedido"); 
                } 
              } color="inherit">
                Voltar ao carrinho
            </Button>
        </Fragment>
      );

    handleSaveCart() {
        let { cliente } = this.state;
        this.setState({ enviandoPedido: true });

        let profile = JSON.parse(localStorage.getItem('_profile'));

        saveNomeCliente(cliente.nome)
        if (cliente.telefone) {
            localStorage.setItem("_telefoneCliente", cliente.telefone)
        }

        let cart = getCart()
        cart.pedido.nomeCliente = cliente.nome + (cliente.telefone ? " - " + this.state.cliente.telefone : "");
        cart.pedido.identificador = profile;
        cart.pedido.dataPendente = new Date();
        saveCart(cart)

        if (cart.pedido.itens && cart.pedido.itens.length === 0 ){
             
            this.setState({ enviandoPedido: false });
            alert("Ops, seu pedido não tem nenhum item. Vamos te direcionar para o cardápio")
            this.props.history.replace("/");

            return
        }

        if (cart.pedido.identificador.tipo === "DELIVERY") {
            let { cliente } = this.state;

            this.history.push({
                pathname: `/pessoa`,
                search: '',
                state: { nome: cliente.nome }
            })


        } else if (!utilizaIdentificadorSecundario() || profile.tipo === "SEQUENCIAL") {
            let service = new AuthService();
            service
                .post("/pedido", cart.pedido)
                .then(retorno => {
                    if (Pixel.isPixelOn()) {
                        let pixelObj = {
                            value: cart.valorTotalPedido,
                            content_name: cart.pedido.nomeCliente,
                            currency: 'BRL',
                        }
                        
                        Pixel.trackEvent('Purchase', pixelObj)
                    }
                    saveUltimoPedido(retorno)
                    if (profile.tipo === "SEQUENCIAL") {
                        saveSequencial(retorno)
                    }
                    clearCart()
                   
                    this.props.history.replace("/closePedido")
                }).catch(err => {
                    console.log(err) 

                  if (err.response.status === 417) {
                        err.response.json().then(elem => {
                            this.props.enqueueSnackbar(elem.errorMessage, { 
                                variant: "error",
                                action: this.actionMsgClick 
                            });
                        });
                    } else {
                        this.props.history.replace("/errors");
                    }
                  
                }).finally(() => {
                    this.setState({ enviandoPedido: false });
                })

        } else {

            this.setState({ enviandoPedido: false });
            this.props.history.push("/dadoIdentificador")
        }
    }


    condicaoSatisfeitaIdentificador() {
        let profile = JSON.parse(localStorage.getItem("_profile"));
        const telefoneNumbersOnly = onlyDigits(this.state.cliente.telefone);
        if (!this.state.cliente.nome) {
            return false;
        }
        if (this.state.cliente.nome.length === 0) {
            return false;
        }
        if (profile.empresa.telefoneMesa) {
            if (!this.state.cliente.telefone) {
                return false
            }
            if (telefoneNumbersOnly.length < 10 || telefoneNumbersOnly.length > 11){
                return false
            }
        }

        return true;
    }

    TextMaskPhone = (props) => {
        const { inputRef, ...other } = props;
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                placeholder="(xx) x xxxx-xxxx"
            />
        );
    }

    render() {
        const { cliente, enviandoPedido } = this.state;
        let profile = JSON.parse(localStorage.getItem("_profile"));

        return ( 
            <Fade in={true}>
                <CardGenerico
                    leftButtonClick={() => window.history.back()}
                    rightButtonClick={event => this.retornarParaListagem(event)}
                    cardMedia={
                        <Box style={{ margin: "0 auto" }}>
                            <img src={Robo} alt="Robo" style={{ maxHeight: "25vh" }} />
                        </Box>
                    }

                    cardContent={
                        <Grid container
                            spacing={3}
                            direction="row"
                            justify="center"
                            alignItems="center" >

                            <Grid item xs={12} >
                                <Typography color="primary" align="center">
                                    <strong>
                                        Estamos quase lá. <br /> Diga para nós seu nome?
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Box pr={2} pl={2}>
                                    <TextField
                                        placeholder="Seu nome"
                                        name="nome"
                                        defaultValue={cliente.nome}
                                        autoFocus={!cliente.nome}
                                        fullWidth
                                        inputProps={{ maxLength: 30 }}
                                        onChange={event => this.handleChange(event)}
                                        variant="outlined"
                                    />

                                    {profile.empresa.telefoneMesa !== false ? (
                                        <TextField
                                            label="Telefone"
                                            style={{ marginTop: "10px" }}
                                            name="telefone"
                                            defaultValue={cliente.telefone}
                                            fullWidth
                                            inputProps={{ maxLength: 30 }}
                                            onChange={event => this.handleChange(event)}
                                            variant="outlined"
                                            type="tel"
                                            InputProps={{
                                                inputComponent: this.TextMaskPhone
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            </Grid>

                        </Grid>
                    }

                    cardActions={
                        <CustomButton
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disabled={!this.condicaoSatisfeitaIdentificador() || enviandoPedido}
                            onClick={event => this.handleSaveCart(event)}>
                            {profile.tipo === "SEQUENCIAL" ? "próximo" :
                                utilizaIdentificadorSecundario() ? (
                                    "Próximo"
                                ) : (
                                        enviandoPedido ? "Enviando..." : "Finalizar pedido"
                                    )
                            }
                        </CustomButton>
                    }
                />

                
            </Fade>

        )
    }
}



export default withSnackbar(withRouter(FormNome));