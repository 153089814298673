import React, { Component } from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import {  alterarPedidoNosUltimosPedidos } from '../../../utils/PedidoUtil';
import AuthService from "../../../services/AuthService";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'; 
import CardGenerico from '../../generic/CardGenerico'
import { withRouter } from 'react-router-dom';
import PicPay from "../../../utils/PicPay";
import LaunchIcon from '@material-ui/icons/Launch';
import ReplayIcon from '@material-ui/icons/Replay';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Robo from "../../../imagens/IconeRoboPicPay.svg"
import Slide from '@material-ui/core/Slide';
import AlertDialog from '../../generic/AlertDialog'

class ConfirmarPagamento extends Component {

    constructor(props) {
        super(props);
        const param = this.props.match.params;
        this.state = {
            uuid: param.uuid,
            pedido: null,
            buscando: false,
            openDialogOnClose: false
        }
        
        this.buscarPedido = this.buscarPedido.bind(this)
        this.buscarPedidoPeloReferenceId = this.buscarPedidoPeloReferenceId.bind(this)
        this.buscarPedidoPeloId = this.buscarPedidoPeloId.bind(this)
        this.abrirLinkPagamentoPicPay = this.abrirLinkPagamentoPicPay.bind(this)
        this.verificarStatusPagamentoPicPay = this.verificarStatusPagamentoPicPay.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onFecharTela = this.onFecharTela.bind(this)
        this.confirmarFecharTela = this.confirmarFecharTela.bind(this)
    }

    componentDidMount(){
        window.addEventListener("focus", this.onFocus)
        this.buscarPedido()
    }
    componentWillUnmount(){
        window.removeEventListener("focus", this.onFocus)
    }

    onFocus() {
        this.buscarPedido()
    }

    buscarPedido(){
        let { uuid } = this.state
        if (uuid){
            if (String(uuid).indexOf('PED_QRPEDIR') >= 0){
                this.buscarPedidoPeloReferenceId(uuid)
            }else{
                this.buscarPedidoPeloId(uuid)
            }
        }
    }

    buscarPedidoPeloId(uuid){
        this.setState({buscando: true})
        let service = new AuthService()
        service.get(`/pedido/uuid/${uuid}`)
            .then(pedido => {
               this.setState({pedido})
               this.verificarStatusPagamentoPicPay(pedido)
        }).catch(err => {
            this.setState({buscando: false})
            console.error(err)
        })
    }


    buscarPedidoPeloReferenceId(referenceId){
        this.setState({buscando: true})
        let service = new AuthService()
        service.get(`/pedido/pagamento/${referenceId}`)
            .then(pedido => {
               this.setState({pedido})
               this.verificarStatusPagamentoPicPay(pedido)
        }).catch(err => {
            this.setState({buscando: false})
            console.error(err)
        })
    }

    verificarStatusPagamentoPicPay(pedido){
        let pagamento = pedido.pagamentos[0]
        let picpayToken = pagamento.formaPagamento.picpayToken
        let referenceId = pagamento.referenceId

        let picPay = new PicPay({picpayToken})
        this.setState({buscando: true})

        picPay.statusRequest(referenceId)
        .then(result => {

            let service = new AuthService()
            pagamento.status = result.status
            
            if (result.authorizationId){
                pagamento.authorizationId = result.authorizationId
            }
            if (result.cancellationId){
                pagamento.cancellationId = result.cancellationId
            }

            service.post(`/pagamento`, pagamento )
                .then(pagamentoRetorno => {
                    pedido.pagamentos[0] = pagamentoRetorno
                    this.setState({pedido})

                    if (pagamentoRetorno.status === "paid"){
                        //enviar pra tela de consulta
                        setTimeout(() => this.props.history.push("/closePedido"), 1500)
                    }
                    this.setState({buscando: false})
                    alterarPedidoNosUltimosPedidos(pedido)

                }).catch(err => {
                    console.error(err)
                    this.setState({buscando: false})
                })

        }).catch(err => {
            console.error(err)
            this.setState({buscando: false})
        })
    }

    abrirLinkPagamentoPicPay(){
        let {pedido} = this.state

        if(pedido){
            let pagamento = pedido.pagamentos[0]
            window.location.href = pagamento.paymentUrl
        }
    }

    onFecharTela(){
        this.setState({openDialogOnClose : true})
        //this.props.history.push("/")
    }


    confirmarFecharTela(){
        this.setState({openDialogOnClose : false})
        this.props.history.push("/")
    }

    
   
    render() {
        const { pedido, buscando, openDialogOnClose } = this.state
        const pagamento = pedido ? pedido.pagamentos[0] : null
        return (
            <React.Fragment>
                <CardGenerico 
                    rightButtonClick={this.onFecharTela}>
                
                    <Box style={{ margin: "0 auto" }}>
                        <img alt='robo' src={Robo} style={{ maxHeight: "15vh" }} />
                    </Box>
                    <Grid container>
                     
                        <Grid item style={{  marginTop: "5%" }} xs={12}>

                            <List>
                            <ListItem button onClick={this.abrirLinkPagamentoPicPay}>
                                <ListItemAvatar>
                                <Avatar>
                                    1
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Abra o app do PicPay" secondary={"Para realizar o pagamento"} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                <Avatar>
                                   2
                                </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Volte aqui 😏" secondary="Para acompanhar o status do seu pedido" />
                            </ListItem>
                          
                            </List>
                            
                        </Grid>

                        <Grid item style={{ textAlign: 'center', marginTop: "5%" }} xs={12}>
                            
                            <Box display="block">
                                {pagamento && pagamento.status !== 'paid' ? (
                                    <React.Fragment>
                                        <Typography vvariant="caption" display="block" 
                                            gutterBottom>Pagamento ainda não confirmado</Typography>
                                        <Button
                                            disabled={buscando}
                                            endIcon={<ReplayIcon/>}
                                            onClick={() => this.verificarStatusPagamentoPicPay(pedido)}>
                                            {buscando ? 'Buscando...' : 'Atualizar'}
                                        </Button> 
                                    </React.Fragment>
                                ) : null}

                                {pagamento && pagamento.status === 'paid' ? (
                                    <Slide direction="down" in={true} timeout={1000}>
                                        <Chip
                                            label="PAGAMENTO CONFIRMADO"
                                            icon={<DoneIcon />}
                                            color="primary" />
                                    </Slide>
                                ) : null}
                                
                            </Box>
                        </Grid>
 

                        <Grid item style={{ textAlign: 'center', marginTop: "10%" }} xs={12}>

                            {pagamento && pagamento.status === 'created' ? (
                                <Button variant="contained" color="primary" 
                                    endIcon={<LaunchIcon/>}
                                    disabled={!pedido} onClick={this.abrirLinkPagamentoPicPay}>Abrir picpay</Button>
                            ) : null}
                           
                        </Grid>
                        
                       
                    </Grid>

                </CardGenerico>
               
                <AlertDialog 
                    open={openDialogOnClose} 
                    title="Atenção" 
                    content={
                        <span>Seu pagamento ainda não foi confirmado. Caso já tenha realizado o 
                            pagamento no PicPay clique em <strong>atualizar</strong> ou então acesse  
                            <strong> Pedidos realizados</strong> no menu principal do app.
                        </span>}

                    handleClose={() => this.setState({openDialogOnClose: false})}
                    dialogActions={
                        <React.Fragment>
                            <Button onClick={this.confirmarFecharTela} color="primary" autoFocus>
                            Ok, entendi
                            </Button>
                        </React.Fragment>
                    }

                    ></AlertDialog>
            </React.Fragment>
        );
    }
}

export default withRouter( ConfirmarPagamento );