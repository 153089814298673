import React from 'react';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from "@material-ui/styles";
import MyThemeProvider, {useThemeContext} from "./pages/context/MyThemeContext"


const App = () => {
  
  // window.addEventListener("beforeunload", ev => {
  //   ev.returnValue = "Tem certeza que deseja fechar ?"
  // })
  
  return (
    <div className="App">
      <MyThemeProvider>
        <AppContext/> 
      </MyThemeProvider>
    </div>
  )
};

const AppContext = function(){
 
  const {myTheme} = useThemeContext()

  return (
    <SnackbarProvider maxSnack={3} 
        >
      <ThemeProvider theme={myTheme} >
            <Routes />
      </ThemeProvider>
    </SnackbarProvider>

  )
}

export default App;


