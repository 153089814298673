import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, MobileStepper } from "@material-ui/core";
import PromoChip from '../generic/PromoChip'
import Utils, { formatarReal, converterURLImagemProduto } from '../../utils/Utils'
import InformacoesNutricionaisProduto from '../generic/InformacoesNutricionaisProduto'
import { trunkStr } from '../../utils/Utils'
import SwipeableViews from 'react-swipeable-views';
import { Dialog, DialogActions } from '@material-ui/core';


// const imgs = [
//     'https://www.brasilagro.com.br/uploads/conteudo/conteudo/2020/03/mASOi/burger-king-do-uruguai-mg8rBI_510x400.png',
//     'https://www.folhadealphaville.com.br/images/articles/3963/b2ap3_medium_bullguer-lanca-red-neck-bacon-duplamente-defumado-geek-publicitario.jpg',
//     'https://www.sopacultural.com/wp-content/uploads/2019/05/Prize-Burger-Dia-do-Hamb%C3%BArguer.jpeg'
// ]


const useStyles = makeStyles((theme) => ({
    /* imagemContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxHeight: "27vh",
        overflow: 'hidden'
    }, */
    imageContainer: {
        width: "100%",
        overflow: "hidden",
        maxHeight: "29vh",
    },

    imageSwap: {
        width: "100%",
        overflow: "hidden",
        maxHeight: "29vh",
        objectFit: "contain"
    },

    imagem: {
        //width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        maxHeight: "25vh",
        //width: "100%",
        //objectFit: "cover"
    },
    descricaoProduto: {
        textTransform: 'uppercase',
        fontFamily: "Bebas Neue",
        fontSize: "1.8em",
    }, link: {
        color: theme.palette.primary.main,
        whiteSpace: "nowrap",
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },

}));


function ImagemProduto(props) {

    const { produto } = props
    const [openInfoProduto, setOpenInfoProduto] = React.useState(false);
    const classes = useStyles();

    return (
        <div>

            {produto.imagens && produto.imagens.length > 0 ? (
                <Imagens produto={produto} />
            ) : (
                <Box display="flex" justifyContent="center" style={{ margin: "0 auto" }}>
                    <img style={{ maxHeight: "15vh" }} alt={`${produto.grupoProduto.tipoGrupoProduto.descricao}`}
                        src={`/assets/images/iconsCircles/${produto.grupoProduto.tipoGrupoProduto.imagem}`} />
                </Box>
            )}


            <Box display="flex" pr={2} pl={2} pt={1}>
                <Box flexGrow={1} >
                    <Typography className={classes.descricaoProduto} color="primary" variant="h2" component="h2">
                        {produto.nome}
                    </Typography>
                </Box>
                <Box flexShrink={0} display="flex" alignItems="center" >
                    {
                        produto.preco > 0 ? (
                            produto.promocaoAtiva ? (
                                <Box pb={1}><PromoChip label={Utils.formatNumber(produto.preco)} /></Box>
                            ) : (
                                <Typography align="right" color="secondary" >
                                    {Utils.formatNumber(produto.preco)}
                                </Typography>
                            )
                        ) : null
                    }
                </Box>
            </Box>

            <Box display="flex" pr={2} pl={2} pt={1} alignItems="center">

                <Box flexGrow={1}>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {trunkStr(produto.descricao, 100)}
                    </Typography>
                </Box>
                <Box>
                    <a className={classes.link} onClick={() => { setOpenInfoProduto(true); }}> saiba mais</a>
                </Box>

            </Box>

            <InformacoesNutricionaisProduto produto={produto} open={openInfoProduto} close={() => setOpenInfoProduto(false)} />

        </div>
    )
}

function ShowImagem({ handleClose, image }) {
    return (
        <Dialog open={image !== null} onClose={handleClose}>
            <img src={image} alt={`Imagem do produto`} style={{ width: "100%" }} />

            <DialogActions>
                <Box><Button onClick={handleClose}>FECHAR</Button></Box>
            </DialogActions>
        </Dialog>
    )
}

function Imagens({ produto }) {

    const classes = useStyles();
    const [showImagem, setShowImagem] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <React.Fragment>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>

                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {produto.imagens.map((step, index) => (
                        <div key={index}  >
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img
                                    src={converterURLImagemProduto(produto.imagens[activeStep])}
                                    alt={'imagem do produto'} className={classes.imageSwap}
                                    onClick={() => setShowImagem(converterURLImagemProduto(produto.imagens[activeStep]))} />
                            ) : null}
                        </div>
                    ))}
                </SwipeableViews>
                <ShowImagem image={showImagem} handleClose={() => setShowImagem(null)} />

                <MobileStepper
                    variant="dots"
                    steps={produto.imagens.length}
                    position="static"
                    activeStep={activeStep}
                />
            </Box>
        </React.Fragment>
    )
}

export default ImagemProduto;
